import { Component, OnInit } from "@angular/core";
import {
  Paper,
  QuestionData,
  QuestionPartReviewStatus,
  QuestionPart,
  ProfessionalBodies,
} from "../../../../../types/types";
import { AuthService } from "../../../services/auth/auth.service";
import { ActivatedRoute } from "@angular/router";
import { PapersService } from "../../../services/papers/papers.service";
import { NotificationsService } from "../../../services/notifications/notifications.service";

@Component({
  selector: "app-question-management",
  templateUrl: "./question-management.component.html",
  styleUrls: ["./question-management.component.scss"],
})
export class QuestionManagementComponent implements OnInit {
  paperId: string | null;
  paper: Paper;
  editingQuestionId: null | string = null;
  add_question = false;
  newQuestion: QuestionData = {
    key: "",
    number: 0,
    paper: "",
    paper_key: "",
    parts: [] as QuestionPart[],
    prof_body: ProfessionalBodies.ASSA,
    question_string: "",
    reviewed: false,
    source: "",
    sub_question_count: 0,
    subject: "",
    subject_key: "",
    subject_name: "",
    timestamp: Date.now(),
    topics: [],
    marks: 0,
    subject_paper: "",
    paperLink: "",
    memoLink: "",
    format_version: 2,
    usage: {
      avgScore: 0,
      reviewCount: 0,
    },
    paperId: "",
  };
  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    public paperService: PapersService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      if (params.get("id")) {
        this.paperId = params.get("id") as string;
        this.loadPaper(this.paperId);
      }
    });
  }

  loadPaper(paperId) {
    this.authService.fs
      .collection("papers")
      .doc(paperId)
      .snapshotChanges()
      .subscribe((doc) => {
        if (doc.payload.exists) {
          this.paper = doc.payload.data() as Paper;
          this.authService.fs
            .collection("questions", (ref) =>
              ref.where("paperId", "==", this.paper.id)
            )
            .snapshotChanges()
            .subscribe((querySnapshot) => {
              const questionData = querySnapshot.map(
                (doc) => doc.payload.doc.data() as QuestionData
              );
              this.paper.questionData = questionData;
              this.paper.questionData.forEach((question) =>
                question.parts.forEach((part) => {
                  part.status = part.status || QuestionPartReviewStatus.review;
                })
              );
              this.paper.questionData.sort((a, b) => a.number - b.number);
            });
        }
      });
  }

  goToDriveFolder(folderId: string) {
    const url = `https://drive.google.com/drive/folders/${folderId}`;
    window.open(url, "_blank");
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  openQuestion(question: QuestionData) {
    this.editingQuestionId = question.key;
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  updateQuestion(question: QuestionData, develop: boolean) {
    return new Promise((resolve, reject) => {
      const collection = develop ? "questions_dev" : "questions";
      if (!develop && question.hasOwnProperty("changesNotInProd")) {
        delete question.changesNotInProd;
      }
      this.authService.fs
        .collection(collection)
        .doc(question.key)
        .set(question)
        .then((data) => {
          alert(`Question updated in ${collection}`);
          resolve(true);
        });
    });
  }

  toRoman(number: number): string {
    if (number <= 0 || number > 30) {
      throw new Error("Number out of range (must be 1..30)");
    }
    const numerals: [string, number][] = [
      ["x", 10],
      ["ix", 9],
      ["v", 5],
      ["iv", 4],
      ["i", 1],
    ];
    let result = "";
    for (const [numeral, value] of numerals) {
      while (number >= value) {
        result += numeral;
        number -= value;
      }
    }
    return result.toLowerCase();
  }

  addQuestion() {
    const parts: QuestionPart[] = new Array(this.newQuestion.sub_question_count)
      .fill(null)
      .map((_, index) => ({
        identifier: this.toRoman(index + 1),
        number: index + 1,
        marks: 0,
        question_html: "",
        memo_html: "",
      }));
    this.newQuestion.key = `${this.paper.id}_${this.newQuestion.number}`;
    this.newQuestion.question_string = this.newQuestion.key;
    this.newQuestion.subject_paper = this.paper.id;
    this.newQuestion.paper = this.paper.paperIdString;
    this.newQuestion.paper_key = this.paper.paperIdString;
    this.newQuestion.paperId = this.paper.id;
    this.newQuestion.reviewed = true;
    this.newQuestion.source = this.paper.source;
    this.newQuestion.subject = this.paper.subjectId;
    this.newQuestion.subject_key = this.paper.subjectId;
    this.newQuestion.subject_name = this.paper.source;
    this.newQuestion.prof_body = this.paper.professionalBody;
    this.newQuestion.question_marks = this.newQuestion.marks;
    this.newQuestion.question_number = this.newQuestion.number;
    this.newQuestion.parts = parts;
    this.newQuestion.topics = [];
    this.newQuestion.usage = {
      avgScore: 0.55,
      reviewCount: 0,
    };
    this.newQuestion.timestamp = 0;
    this.newQuestion.format_version = 2;
    if (this.paper.memoUrl) {
      this.newQuestion.memoLink = this.paper.memoUrl;
    } else {
      delete this.newQuestion.memoLink;
    }
    if (this.paper.paperUrl) {
      this.newQuestion.paperLink = this.paper.paperUrl;
    } else {
      delete this.newQuestion.paperLink;
    }
    if (this.paper.questionData) {
      const questionExists = this.paper.questionData.some(
        (question) => question.number === this.newQuestion.number
      );
      if (questionExists) {
        alert(
          `Question number ${this.newQuestion.number} already exists in this paper.`
        );
        return;
      }
    }
    if (this.newQuestion.sub_question_count <= 0) {
      alert(`Sub question count must be > 0.`);
      return;
    }

    this.authService.fs
      .collection("questions")
      .doc(this.newQuestion.key)
      .set(this.newQuestion)
      .then((docRef) => {
        alert("Question added");
        this.add_question = false;
      })
      .catch((error) => {
        alert("Error adding question");
      });
  }

  updatePaperStructure() {
    this.authService.fs
      .collection("papers")
      .doc(this.paper.id)
      .update({ paperMapGuidance: this.paper.paperMapGuidance })
      .then(() => {
        this.notificationsService.snack(
          "Paper structure guidance updated successfully."
        );
      })
      .catch((error) => {
        this.notificationsService.snack(
          "Error updating paper structure guidance: " + error.message
        );
      });
  }
}
