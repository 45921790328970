import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase, AngularFireObject } from "@angular/fire/database";
import { AngularFirestore } from "@angular/fire/firestore";
import * as firebase from "firebase/app";
import { Observable, Subject } from "rxjs";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { first } from "rxjs/operators";
import { NotificationsService } from "../notifications/notifications.service";
import { TermsAndConditionsPopComponent } from "../../dialogs/terms-and-conditions-pop/terms-and-conditions-pop.component";
import { JobBoardInterestPopComponent } from "../../dialogs/job-board-interest-pop/job-board-interest-pop.component";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { resolve } from "url";
import {
  Fees,
  ProfessionalBodies,
  SignIn,
  User,
  GenerateInvoice,
  InvoiceType,
  Tasks,
  JobBoardInterest,
  SlackChannels,
  Assignment,
  ReviewType,
  Review,
  ExamCandidate,
  AssignmentResult,
  SubjectData,
  AttemptText,
  AiConfig,
  stage,
} from "../../../../types/types";
import { CreditInstructionsComponent } from "../../dialogs/credit-instructions/credit-instructions.component";
import { environment } from "../../../../types/environments/environment";
declare let gtag: Function;

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public dev = false;
  public user: Observable<firebase.User | null>;
  public userDetails: firebase.User;
  public accountData: AngularFireObject<{ any }>;
  public admin = false;
  public marker = false;
  public courseLeader = false;
  public dataEntry = false;
  public tuitionCourseParticipant = false;
  public userInfo: User;
  public loggedin = false;
  public logging_in = true;
  public processing = false;
  public package_valid = true;
  public marking_status = false;
  public deepLink = "";
  public displayName = "";
  public topics = null;
  public own_review_selected = {};
  public termsAccepted = false;
  public usingEmailAndPassword = false;
  public connectionLoadedCount = 0;
  public internetConnected = true;
  public professionalBody?: ProfessionalBodies;
  public creditQuoteObj: GenerateInvoice;
  public showJobBoardInterest: Boolean = false;
  public jobBoardInterest: JobBoardInterest;
  public aiConfig: AiConfig;

  constructor(
    private _firebaseAuth: AngularFireAuth,
    public router: Router,
    public db: AngularFireDatabase,
    public fs: AngularFirestore,
    public analytics: AngularFireAnalytics,
    private notificationsService: NotificationsService,
    public dialog: MatDialog
  ) {
    this.user = _firebaseAuth.authState;
    this.user.subscribe(async (user) => {
      if (user) {
        this.loggedin = true;
        this.logging_in = false;
        this.userDetails = user;
        this.notifyServer(user);
        this.loadAiConfig();
        this.getUserInfo(user.uid).subscribe(async (data) => {
          if (data) {
            const userInfo = data as User;
            this.userInfo = userInfo as User;
            this.userInfo.professionalBody = this.getProfessionalBody(
              this.userInfo.professionalBody
            );
            if (this.userInfo.name === "" && user.displayName) {
              this.updateUserName(user.displayName);
            }
            if (this.userInfo.professionalBody !== undefined) {
              this.professionalBody = this.userInfo.professionalBody;
            }
          }
          this.termsAcceptedCheck();
        });
        await this.loadUserRights();
        this.routeUser();
      } else {
        this.logging_in = false;
      }
      this.db
        .object(".info/connected")
        .valueChanges()
        .subscribe((connectedSnap) => {
          if (this.connectionLoadedCount > 1) {
            if (connectedSnap === true) {
              this.internetConnected = true;
            } else {
              this.internetConnected = false;
            }
          }
          this.connectionLoadedCount += 1;
        });
    });
  }

  routeUser() {
    if (environment.envName === stage.PROD) {
      if (this.marker) {
        return this.router.navigate(["/marker"]);
      }
      if (this.userInfo) {
        return this.router.navigate(["/subjects"]);
      }
    }
  }

  getProfessionalBody(professionalBody: ProfessionalBodies | undefined) {
    if (
      professionalBody &&
      !environment.professionalBodies.includes(professionalBody)
    ) {
      return undefined;
    }
    return professionalBody;
  }

  loadAiConfig() {
    return this.fs
      .collection("config")
      .doc("ai")
      .valueChanges()
      .subscribe((aiConfig) => {
        this.aiConfig = aiConfig as AiConfig;
      });
  }

  resetPassword(email: string) {
    this._firebaseAuth.auth
      .sendPasswordResetEmail(email)
      .then((d) => {
        this.notificationsService.snack(`Reset email sent to ${email}`);
      })
      .catch((e) => {
        this.notificationsService.snack(
          `Failed to send reset email to ${email}`
        );
      });
  }

  loadUserRights() {
    return new Promise<boolean>(async (resolve, reject) => {
      this.admin = await this.checkAdmin();
      this.marker = await this.checkMarker();
      this.courseLeader = await this.checkAssignmentMarker();
      this.tuitionCourseParticipant = await this.userIsInATuitionCourse();
      this.dataEntry = await this.checkDataEntryAccess();
      if (this.dataEntry) {
        this.router.navigate(["/papers"]);
      }
      resolve(true);
    });
  }

  async termsAcceptedCheck() {
    if (!this.userInfo.hasOwnProperty("tcsAccepted")) {
      this.openTermsAndConditions();
    } else {
      if (this.userInfo["tcsAccepted"] !== true) {
        this.openTermsAndConditions();
      } else {
        this.termsAccepted = true;
      }
    }
  }

  openTermsAndConditions() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog
      .open(TermsAndConditionsPopComponent, dialogConfig)
      .afterClosed()
      .subscribe((termsAccepted) => {
        if (!termsAccepted) {
          this.router.navigate(["/landing"]);
        } else {
          this.fs
            .collection("users")
            .doc(this.userDetails.uid)
            .update({ tcsAccepted: true })
            .then((success) => {
              this.notificationsService.snack("Terms accepted. Welcome!");
              this.termsAccepted = true;
              gtag("event", "conversion", {
                send_to: "AW-338334388/3cKwCJG-t9ADELSlqqEB",
              });
            });
        }
      });
  }

  aiIntroductionComplete() {
    this.fs
      .collection("users")
      .doc(this.userDetails.uid)
      .update({ aiRecIntroComplete: true })
      .then((d) => {})
      .catch((e) => {});
  }

  updateUserProfBody(professionalBody: ProfessionalBodies) {
    return new Promise((resolve, reject) => {
      this.fs
        .collection("users")
        .doc(this.userDetails.uid)
        .update({ professionalBody })
        .then((d) => {
          resolve(true);
        })
        .catch((e) => {
          reject();
        });
    });
  }

  updateUserName(displayName) {
    this.fs
      .collection("users")
      .doc(this.userDetails.uid)
      .update({ name: displayName });
  }

  confirmEmail() {
    this.fs
      .collection("users")
      .doc(this.userDetails.uid)
      .update({ emailVerified: true })
      .then((d) => {
        this.notificationsService.snack("Email confirmed");
      });
  }

  notifyServer(user: firebase.User) {
    const { email, displayName, uid } = user;
    if (email) {
      let chosenDisplayName = displayName;
      if (!chosenDisplayName) {
        chosenDisplayName = this.displayName;
      }
      const data: SignIn = {
        uid,
        email,
        displayName: chosenDisplayName,
        chosenName: this.displayName,
        processed: false,
        timestamp: new Date().getTime(),
        usingEmailAndPassword: this.usingEmailAndPassword,
        platform: environment.platform,
      };
      this.fs.collection("signIns").add(data);
    }
  }

  checkAdmin() {
    return new Promise<boolean>(async (resolve, reject) => {
      this.getAdmins(this.userDetails.uid).subscribe((subjects) => {
        if (!subjects.empty) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  checkMarker() {
    return new Promise<boolean>(async (resolve, reject) => {
      if (this.admin) {
        resolve(true);
      } else {
        this.getMarkerSubjects(this.userDetails.uid).subscribe((subjects) => {
          if (!subjects.empty) {
            subjects.docs.forEach(async (subject) => {
              const key = subject.data()["subjectId"];
              const code = await this.getSubjectCodeFromKey(key);
              this.userInfo[code + "_marker"] = true;
            });
            resolve(true);
          } else {
            resolve(false);
          }
        });
      }
    });
  }

  checkAssignmentMarker() {
    return new Promise<boolean>(async (resolve, reject) => {
      if (this.admin) {
        resolve(true);
      } else {
        this.getMarkerAssignments(this.userDetails.uid).subscribe(
          (assignments) => {
            if (!assignments.empty) {
              resolve(true);
            } else {
              resolve(false);
            }
          }
        );
      }
    });
  }

  getAssignments(): Promise<Assignment[]> {
    return new Promise<any>(async (resolve, reject) => {
      this.getMarkerAssignments(this.userDetails.uid).subscribe(
        (assignments) => {
          const assignmentStore: Assignment[] = [];
          if (!assignments.empty) {
            assignments.forEach((assigment) => {
              assignmentStore.push(assigment.data() as Assignment);
            });
            resolve(assignmentStore);
          } else {
            resolve(assignmentStore);
          }
        }
      );
    });
  }

  checkDataEntryAccess() {
    return new Promise<boolean>(async (resolve, reject) => {
      if (this.admin) {
        resolve(false);
      } else {
        this.getDataEntryAccess(this.userDetails.uid).subscribe((documents) => {
          if (!documents.empty) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      }
    });
  }

  getSubjectCodeFromKey(key): Promise<string> {
    return new Promise(async (resolve, reject) => {
      this.fs
        .collection("subjects", (ref) => ref.where("key", "==", key))
        .get()
        .subscribe((d) => {
          d.forEach((subject) => {
            resolve(subject.data()["code"] as string);
          });
        });
    });
  }

  getSubject(key): Promise<SubjectData> {
    return new Promise(async (resolve, reject) => {
      this.fs
        .collection("subjects")
        .doc(key)
        .get()
        .subscribe((d) => {
          resolve(d.data() as SubjectData);
        });
    });
  }

  getSubjectNameFromKey(key) {
    return new Promise<string>(async (resolve, reject) => {
      this.fs
        .collection("subjects", (ref) => ref.where("key", "==", key))
        .get()
        .subscribe((d) => {
          d.forEach((subject) => {
            resolve(subject.data()["name"] as string);
          });
        });
    });
  }

  getSubjectDisplayCodeFromKey(key) {
    return new Promise<string>(async (resolve, reject) => {
      this.fs
        .collection("subjects", (ref) => ref.where("key", "==", key))
        .get()
        .subscribe((d) => {
          d.forEach((subject) => {
            resolve(subject.data()["codeDisplay"] as string);
          });
        });
    });
  }

  validateSubjectKey(key) {
    return new Promise(async (resolve, reject) => {
      this.fs
        .collection("subjects")
        .get()
        .subscribe((subjects) => {
          const subjectKeys: string[] = [];
          subjects.forEach((subject) => {
            if (
              subject.data()["active"] ||
              (!subject.data()["active"] && this.admin)
            ) {
              subjectKeys.push(subject.data()["code"]);
            }
          });
          resolve(subjectKeys.includes(key));
        });
    });
  }

  getSessionTS() {
    const year = new Date().getFullYear();
    var month = 1;
    if (new Date().getMonth() >= 6) {
      month = 6;
    }
    return new Date(year, month - 1, 1).getTime();
  }

  getSession() {
    const year = new Date().getFullYear();
    var session = 1;
    if (new Date().getMonth() > 5) {
      session = 2;
    }
    return session;
  }

  getYearSession() {
    const year = new Date().getFullYear();
    var session = 1;
    if (new Date().getMonth() > 5) {
      session = 2;
    }
    return year + "-" + session;
  }

  getYear() {
    const year = new Date().getFullYear();
    return year;
  }

  getCreditInstructionsData(subjectId: null | string) {
    return new Promise<Fees>(async (resolve, reject) => {
      this.fs
        .collection("config")
        .doc("fees")
        .get()
        .subscribe(async (d) => {
          const fees = d.data();
          const markerFees = {
            ...fees,
          } as Fees;
          if (subjectId !== null) {
            const subject = await this.getSubject(subjectId);
            markerFees.creditsPerMark = subject.creditsPerMark;
            markerFees.subjectCodeDisplay = subject.codeDisplay;
          }
          resolve(markerFees);
        });
    });
  }

  getMarkersReviews() {
    const uid = this.userDetails["uid"];
    const ts = this.getSessionTS();
    return this.fs
      .collection("reviews", (ref) =>
        ref
          .where("reviewType", "==", "actuary")
          .where("state", "==", 2)
          .where("reviewed", "==", true)
          .where("reviewed_by", "==", uid)
          .where("time_submitted", ">=", ts)
          .orderBy("time_submitted", "desc")
      )
      .snapshotChanges();
  }

  signInWithGoogle() {
    this.logging_in = true;
    this.usingEmailAndPassword = false;
    return this._firebaseAuth.auth
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((user) => {
        this.logging_in = false;
      })
      .catch((error) => {
        this.logging_in = false;
        this.notificationsService.snack(error.message);
      });
  }

  signInWithEmail(email: string, password: string) {
    this.logging_in = true;
    this.usingEmailAndPassword = true;
    return this._firebaseAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        this.logging_in = false;
      })
      .catch((error) => {
        this.logging_in = false;
        this.notificationsService.snack(error.message);
      });
  }

  signUpWithEmail(email: string, password: string, name: string) {
    if (!name) {
      return this.notificationsService.snack("Please enter a name");
    }
    if (name.length <= 1 || name === "") {
      return this.notificationsService.snack("Please enter a name");
    }
    this.usingEmailAndPassword = true;
    this.logging_in = true;
    this.displayName = name;
    return this._firebaseAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        this.logging_in = false;
      })
      .catch((error) => {
        this.logging_in = false;
        this.notificationsService.snack(error.message);
      });
  }

  logout() {
    this.logging_in = false;
    this.usingEmailAndPassword = false;
    this._firebaseAuth.auth.signOut().then((res) => {
      this.loggedin = false;
      this.admin ? "" : this.notificationsService.postToSlack("Signout");
      this.notificationsService.snack("You have been signed out");
      this.router.navigate(["landing"]);
    });
  }

  goToDesktop(subject) {
    if (window.innerWidth < 1000) {
      return this.notificationsService.snack(
        "Please use a bigger browser for the best experience"
      );
    }
    return this.router.navigate(["/desktop/" + subject]);
  }

  goToAdmin() {
    this.router.navigate(["/admin"]);
  }

  goToExamBreakdown(subject) {
    this.router.navigate(["/exam-breakdown/" + subject]);
  }

  goToPage(page) {
    this.router.navigate(["/" + page]);
  }

  getUserInfoOnce(uid) {
    return new Promise<User>(async (resolve, reject) => {
      this.fs
        .collection("users")
        .doc(uid)
        .valueChanges()
        .subscribe((data) => {
          if (data) {
            this.userInfo = data as User;
            this.userInfo.professionalBody = this.getProfessionalBody(
              this.userInfo.professionalBody
            );
            resolve(data as User);
          } else {
            reject();
          }
        });
    });
  }

  getUserInfo(uid) {
    return this.fs.collection("users").doc(uid).valueChanges();
  }

  loadAttemptText(questionKey): Promise<AttemptText | undefined> {
    return new Promise((resolve, reject) => {
      this.fs
        .collection("attempts")
        .doc(`${this.userDetails.uid}_${questionKey}`)
        .get()
        .subscribe((doc) => {
          if (doc.exists) {
            const data = doc.data() as AttemptText;
            resolve(data);
          } else {
            resolve(undefined);
          }
        });
    });
  }

  saveAttemptText(questionKey, attemptText) {
    this.fs
      .collection("attempts")
      .doc(`${this.userDetails["uid"]}_${questionKey}`)
      .set({
        attemptText,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        uid: this.userDetails["uid"],
        questionKey: questionKey,
      });
  }

  getAdmins(uid) {
    return this.fs
      .collection("admins", (ref) => ref.where("uid", "==", uid))
      .get();
  }

  getMarkerSubjects(uid) {
    return this.fs
      .collection("markers", (ref) =>
        ref.where("uid", "==", uid).where("active", "==", true)
      )
      .get();
  }

  getMarkerAssignments(uid) {
    const currentSession = this.getSession();
    const currentYear = this.getYear();
    return this.fs
      .collection("assignments", (ref) =>
        ref
          .where("reviewerUid", "==", uid)
          .where("session", "==", currentSession)
          .where("year", "==", currentYear)
          .orderBy("dueDate", "asc")
      )
      .get();
  }

  getDataEntryAccess(uid) {
    if (this.admin) {
      return this.fs.collection("data_entry_access").get();
    }
    return this.fs
      .collection("data_entry_access", (ref) => ref.where("uid", "==", uid))
      .get();
  }

  userIsInATuitionCourse() {
    return new Promise<boolean>(async (resolve, reject) => {
      if (this.admin) {
        resolve(true);
      } else {
        const year = new Date().getUTCFullYear();
        const session = new Date().getMonth() > 5 ? 2 : 1;

        this.fs
          .collection("exam_candidates", (ref) =>
            ref
              .where("uid", "==", this.userDetails.uid)
              .where("year", "==", year)
              .where("session", "==", session)
          )
          .get()
          .subscribe((records) => {
            if (!records.empty) {
              if (records.docs.length > 0) {
                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          });
      }
    });
  }

  userIsParticipantInCourse(uid, subjectCode) {
    return new Promise<boolean>(async (resolve, reject) => {
      const year = new Date().getUTCFullYear();
      const session = new Date().getMonth() > 5 ? 2 : 1;
      this.fs
        .collection("exam_candidates", (ref) =>
          ref
            .where("uid", "==", uid)
            .where("code", "==", subjectCode)
            .where("year", "==", year)
            .where("session", "==", session)
        )
        .get()
        .subscribe((records) => {
          if (!records.empty) {
            let courses = 0;
            records.docs.map((course) => {
              courses += course.data()["courses"].length;
            });
            if (courses > 0) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            resolve(false);
          }
        });
    });
  }

  async getJobBoardInterest() {
    this.jobBoardInterest = await this.getJobBoardResponse(this.userInfo.uid);
    if (this.jobBoardInterest.interest) {
      this.showJobBoardInterest = false;
    } else {
      this.showJobBoardInterest = true;
    }
  }

  getJobBoardResponse(uid) {
    return new Promise<JobBoardInterest>(async (resolve, reject) => {
      try {
        this.fs
          .collection("job_board_interest", (ref) =>
            ref.where("uid", "==", uid)
          )
          .get()
          .subscribe((records) => {
            if (!records.empty) {
              let jbi = {};
              records.docs.map((course) => {
                jbi = course.data();
              });
              resolve(jbi as JobBoardInterest);
            } else {
              const jbi = {
                timeCreated: -1,
                timeCompleted: -1,
                userEmail: this.userInfo.email,
                uid: this.userInfo.uid,
              };
              resolve(jbi as JobBoardInterest);
            }
          });
      } catch (error) {}
    });
  }

  openJobBoardInterest() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog
      .open(JobBoardInterestPopComponent, dialogConfig)
      .afterClosed()
      .subscribe((interest) => {
        this.jobBoardInterest.interest = interest;
        this.jobBoardInterest.timeCompleted = new Date().getTime();
        this.jobBoardInterest.timeCreated = new Date().getTime();
        let message = "Interest: " + interest;
        this.notificationsService.postToSlack(
          message,
          SlackChannels.jobBoardInterest
        );
        this.fs
          .collection("job_board_interest")
          .add(this.jobBoardInterest)
          .then((success) => {
            this.notificationsService.snack("Thank you for your input!");
            this.showJobBoardInterest = false;
          });
      });
  }

  peerReviews(uid: string, subjectCode: string) {
    return new Promise<boolean>(async (resolve, reject) => {
      this.fs
        .collection("config")
        .doc("features")
        .get()
        .subscribe(async (features) => {
          const data = features.data();
          if (features.exists && data) {
            if (data["peerReviews"]) {
              const userIsInCourse = await this.userIsParticipantInCourse(
                uid,
                subjectCode
              );
              resolve(userIsInCourse);
            } else {
              resolve(false);
            }
          } else {
            resolve(false);
          }
        });
    });
  }

  async openCreditInstructionsDialog(subjectId: null | string = null) {
    if (this.professionalBody === ProfessionalBodies.ASSA) {
      const markFees = await this.getCreditInstructionsData(subjectId);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = markFees;
      this.dialog
        .open(CreditInstructionsComponent, dialogConfig)
        .afterClosed()
        .subscribe((data) => {
          if (data) {
            const { numberOfCredits, costPerCredit } = data;
            this.quoteCredits(numberOfCredits, costPerCredit);
          }
        });
    } else if (this.professionalBody === ProfessionalBodies.IFOA) {
      window.open("https://buy.thinkactuary.com/store/credits", "_self");
    } else {
      this.notificationsService.snack(
        "Please select a professional body first"
      );
    }
  }

  async quoteCredits(numberOfCredits: number, costPerCredit: number) {
    if (numberOfCredits > 0 && costPerCredit > 0) {
      this.creditQuoteObj = {
        type: InvoiceType.credits,
        items: [
          {
            description: "Marking credits",
            price: costPerCredit,
            quantity: numberOfCredits,
          },
        ],
        uid: this.userInfo.uid,
        timestamp: new Date().getTime(),
        email: this.userInfo.email,
        user_email: this.userInfo.email,
        customer: [this.userInfo.email],
        verified: false,
        added_to_account: false,
        displayName: this.userInfo.name,
        refDocumentId: "",
        entityName: environment.entity,
        platform: environment.platform,
      };
      this.notificationsService.createTask(
        Tasks.credits_quote,
        this.creditQuoteObj
      );
      this.notificationsService.snack(
        "Invoice sent. Your credits will be allocated once your proof of payment is uploaded."
      );
    }
  }

  getAssignmentCandidates(
    subjectCd,
    examSession,
    examYear
  ): Promise<ExamCandidate[]> {
    return new Promise<any>(async (resolve, reject) => {
      this.fs
        .collection("exam_candidates", (ref) =>
          ref
            .where("code", "==", subjectCd)
            .where("session", "==", examSession)
            .where("year", "==", examYear)
        )
        .get()
        .subscribe((candidates) => {
          const candidateStore: ExamCandidate[] = [];
          candidates.forEach((candidate) => {
            candidateStore.push(candidate.data() as ExamCandidate);
          });
          resolve(candidateStore);
        });
    });
  }

  getAssignmentReview(uid, questionKey): Promise<AssignmentResult> {
    return new Promise(async (resolve, reject) => {
      const result = {
        uid,
        questionKey,
        score: "Not attempted",
      };
      this.fs
        .collection("reviews", (ref) =>
          ref
            .where("questionKey", "==", questionKey)
            .where("user", "==", uid)
            .where("time_submitted", ">=", this.getSessionTS())
            .where("state", "==", 2)
            .where("reviewType", "==", ReviewType.assignment)
            .orderBy("time_submitted", "desc")
            .limitToLast(1)
        )
        .get()
        .subscribe((reviews) => {
          if (reviews.empty) {
            resolve(result);
          }
          reviews.forEach((review) => {
            const r = review.data() as Review;
            result.score = `${r.score_mark}/${r.mark}`;
            resolve(result);
          });
        });
    });
  }
}
