import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { Fees } from '../../../../types/types';
@Component({
  selector: 'app-credit-instructions',
  templateUrl: './credit-instructions.component.html',
  styleUrls: ['./credit-instructions.component.scss'],
})
export class CreditInstructionsComponent implements OnInit {
  public creditsToBuy = 100;
  public minCreditsToBuy = 20;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Fees,
    private notificationsService: NotificationsService,
    private dialogRef: MatDialogRef<CreditInstructionsComponent>
  ) {}

  ngOnInit() {}

  purchaseCredits() {
    if (this.creditsToBuy < this.minCreditsToBuy) {
      this.notificationsService.snack(
        `We require a minimum number of credits of ${this.minCreditsToBuy}`
      );
    } else {
      this.dialogRef.close({
        numberOfCredits: this.creditsToBuy,
        costPerCredit: this.data.costPerCredit,
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
