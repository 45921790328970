import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root',
})
export class MarkerGuardService implements CanActivate {
  constructor(private authService: AuthService) {}
  canActivate() {
    return new Promise<boolean>(async (resolve, reject) => {
      this.authService.user.subscribe(async user => {
        if (user) {
          const admin = await this.authService.checkAdmin();
          const marker = await this.authService.checkMarker();
          if (admin || marker) {
            resolve(true);
          }
        }
        resolve(false);
      });
    });
  }
}
