import { Injectable } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { AdminService } from "../../services/admin/admin.service";
import { QuestionData, SubjectData, Paper } from "../../../../types/types";
import { DataServiceService } from "../data/data-service.service";

@Injectable({
  providedIn: "root",
})
export class PapersService {
  public loaded = false;
  public papers: Paper[] = [];
  public showPapers: Paper[] = [];
  public subjectsToDisplay = "all";

  constructor(
    private authService: AuthService,
    private data: DataServiceService
  ) {
    this.authService.user.subscribe(async (user) => {
      if (user) {
        this.data.loadSubjects(user.uid);
        this.loadPapers();
      }
    });
  }

  setSubject(subjectId: string) {
    this.showPapers = [];
    if (subjectId === "all") {
      this.papers.forEach((paper) => {
        this.showPapers.push(paper);
      });
    } else {
      this.papers.forEach((paper) => {
        if (paper.subjectId === subjectId) {
          this.showPapers.push(paper);
        }
      });
    }
  }

  getSubject(subject: string) {
    return new Promise<SubjectData>(async (resolve, reject) => {
      this.authService.fs
        .collection("subjects")
        .doc(subject)
        .get()
        .subscribe((d) => {
          if (d.exists) {
            const data = d.data() as SubjectData;
            resolve(data);
          } else {
            reject("Subject Not Found");
          }
        });
    });
  }

  setPaperReleaseStatus(paper: Paper) {
    this.authService.fs
      .collection("papers")
      .doc(paper.id)
      .update(paper)
      .then((d) => {
        console.log("Paper updated");
      });
  }

  async loadPapers() {
    this.authService.fs
      .collection("papers")
      .get()
      .subscribe((snapshot) => {
        this.papers = snapshot.docs.map((doc) => doc.data() as Paper);
        const questionPromises = this.papers.map((paper) =>
          this.loadQuestions(paper.id)
        );
        Promise.all(questionPromises).then((questionDataArray) => {
          this.papers.forEach((paper, index) => {
            paper.questionData = questionDataArray[index];
          });
          this.loaded = true;
        });
        this.setSubject("f106");
      });
  }

  loadQuestions(paperId: string): Promise<QuestionData[]> {
    return new Promise<QuestionData[]>((resolve, reject) => {
      this.authService.fs
        .collection("questions", (ref) => ref.where("paperId", "==", paperId))
        .get()
        .subscribe((querySnapshot) => {
          const questions = querySnapshot.docs.map(
            (doc) => doc.data() as QuestionData
          );
          resolve(questions);
        });
    });
  }
}
