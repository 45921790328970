import { PlatformNames, Platforms, ProfessionalBodies } from "../types";
import { stage } from "../types";
import { environment as env } from "./environmentBase";

export const environment = {
  ...env,
  platform: Platforms.law,
  appName: PlatformNames[Platforms.law],
  description:
    "ThinkLawyer helps get you better prepared for your exam. Track your performance over time to make sure you are ready on exam day.",
  favicon: "../assets/img/favicons/thinklawyer.ico",
  envName: stage.DEV,
  production: false,
  professionalBodies: [
    ProfessionalBodies.ADVOCATE,
    ProfessionalBodies.ATTORNEY,
  ],
  subjects: [
    {
      code: "XXX",
      name: "XXX",
      questions: "",
      reviews: "",
      professionalBody: ProfessionalBodies.ADVOCATE,
    },
    {
      code: "XXX",
      name: "XXX",
      questions: "",
      reviews: "",
      professionalBody: ProfessionalBodies.ATTORNEY,
    },
  ],
  testimonials: [],
  community: "law",
  email: "questions@thinklawyer.ai",
  firebase: {
    apiKey: "AIzaSyBYlguUicVFnrLsP4BnQSAwTrU7l4b_690",
    authDomain: "thinkactuary-develop.firebaseapp.com",
    databaseURL: "https://thinkactuary-develop-default-rtdb.firebaseio.com",
    projectId: "thinkactuary-develop",
    storageBucket: "thinkactuary-develop.appspot.com",
    messagingSenderId: "447134283864",
    appId: "1:447134283864:web:5ee2f09920c6a32f15322d",
  },
};
