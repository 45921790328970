import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Desktop, Tasks } from "../../../../types/types";
import { AuthService } from "../../services/auth/auth.service";
import { NotificationsService } from "../../services/notifications/notifications.service";
@Component({
  selector: "app-ai",
  templateUrl: "./ai.component.html",
  styleUrls: ["./ai.component.scss"],
})
export class AI {
  public desktop: Desktop;
  constructor(
    private dialogRef: MatDialogRef<AI>,
    @Inject(MAT_DIALOG_DATA) data: Desktop,
    private authService: AuthService,
    private notificationsService: NotificationsService
  ) {
    this.desktop = data;
    this.loadDesktop();
  }
  loadDesktop() {
    this.authService.fs
      .collection("desktop")
      .doc(`${this.desktop.userId}_${this.desktop.subjectId}`)
      .snapshotChanges()
      .subscribe((snapshot) => {
        if (snapshot.payload.exists) {
          this.desktop = snapshot.payload.data() as Desktop;
        }
      });
  }
  toggleAi(aiEnabled: boolean) {
    this.authService.fs
      .collection("desktop")
      .doc(`${this.desktop.userId}_${this.desktop.subjectId}`)
      .update({ aiEnabled })
      .then((d) => {});
  }
  async toggleAiType() {
    const smartAi = this.desktop.smartAi;
    this.authService.fs
      .collection("desktop")
      .doc(`${this.desktop.userId}_${this.desktop.subjectId}`)
      .update({ smartAi })
      .then((d) => {});
  }
  getDesktop(userId: string, subjectId: string): Promise<Desktop> {
    return this.authService.fs
      .collection("desktop")
      .doc(`${userId}_${subjectId}`)
      .get()
      .toPromise()
      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {
          return documentSnapshot.data() as Desktop;
        }
        throw new Error("Desktop not found");
      });
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
