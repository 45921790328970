import { AuthService } from '../auth/auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(private authService: AuthService) {}
  canActivate() {
    return new Promise<boolean>(async (resolve, reject) => {
      this.authService.user.subscribe(async user => {
        if (user) {
          resolve(true);
        }
        resolve(false);
      });
    });
  }
}
