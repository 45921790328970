import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-job-board-interest-pop',
  templateUrl: './job-board-interest-pop.component.html',
  styleUrls: ['./job-board-interest-pop.component.scss'],
})
export class JobBoardInterestPopComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<JobBoardInterestPopComponent>) {}

  ngOnInit() {}

  close(termsAccepted: boolean) {
    this.dialogRef.close(termsAccepted);
  }
}
