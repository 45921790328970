import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { AuthService } from '../../services/auth/auth.service';
import { DataServiceService } from '../../services/data/data-service.service';
import { SlackChannels } from '../../../../types/types';

@Component({
  selector: 'app-exam-breakdown',
  templateUrl: './exam-breakdown.component.html',
  styleUrls: ['./exam-breakdown.component.scss'],
})
export class ExamBreakdownComponent implements OnInit {
  public subjectCode = '';
  public chartType: string = 'horizontalBar';
  public top_importance = [];
  public top_labels = [];
  public c1_fill = 'rgba(81, 203, 206, 0.2)'; // primary
  public c1_border = 'rgba(81, 203, 206, 1)'; // primary
  public c2_fill = 'rgba(204, 0, 204, 0.2)'; // purple
  public c2_border = 'rgba(204, 0, 204, 1)'; // purple
  public c3_fill = 'rgba(253, 95, 0, 0.2)'; // orange
  public c3_border = 'rgba(253, 95, 0, 1)'; // orange
  public c1_low_lim = 0;
  public c2_low_lim = -1;
  public loadingChart = true;
  public chartDatasets: Array<any> = [{ data: this.top_importance, label: 'Importance Measure' }];
  public chartLabels: Array<any> = this.top_labels;
  public backgroundColor = [];
  public borderColor = [];
  public chartColors: Array<any> = [
    {
      backgroundColor: this.backgroundColor,
      borderColor: this.borderColor,
      borderWidth: 2,
    },
  ];
  public chartOptions: any = {
    responsive: true,
  };
  public chartClicked(e: any): void {}
  public chartHovered(e: any): void {}
  constructor(
    private authService: AuthService,
    private notificationsService: NotificationsService,
    private dataService: DataServiceService
  ) {
    this.authService.user.subscribe(async user => {
      if (user) {
        this.subjectCode = this.authService.router.url.replace('/exam-breakdown/', '');
        const validSubject = await this.authService.validateSubjectKey(this.subjectCode);
        if (validSubject) {
          this.notificationsService.postToSlack('exam-breakdown', SlackChannels.pages);
          this.loadBreakdown(this.subjectCode);
        } else {
          this.authService.router.navigate(['subjects']);
        }
      }
    });
  }

  ngOnInit() {}

  async loadBreakdown(subjectCode) {
    let topicImportance = await this.dataService.topicImportance(subjectCode);
    this.loadValuesIntoChart(topicImportance);
  }

  loadValuesIntoChart(items) {
    for (var i in items) {
      this.top_labels.push(items[i]['topic']);
      this.top_importance.push(items[i]['topicImportance']);

      // setting colors
      if (items[i][1] < this.c2_low_lim) {
        this.borderColor.push(this.c3_border);
        this.backgroundColor.push(this.c3_fill);
      } else if (items[i][1] < this.c1_low_lim) {
        this.borderColor.push(this.c2_border);
        this.backgroundColor.push(this.c2_fill);
      } else {
        this.borderColor.push(this.c1_border);
        this.backgroundColor.push(this.c1_fill);
      }
    }
    this.loadingChart = false;
  }
}
