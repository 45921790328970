import { Component, Input, OnInit } from "@angular/core";
import { AdminService } from "../../../services/admin/admin.service";
import { Observable } from "rxjs";
import * as ClassicEditor from "../../../ckeditor5";
import { AuthService } from "../../../services/auth/auth.service";
import { DataServiceService } from "../../../services/data/data-service.service";
import {
  QuestionData,
  QuestionPartReviewStatus,
  Topic,
} from "../../../../../types/types";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { ChangeEvent } from "@ckeditor/ckeditor5-angular/ckeditor.component";

@Component({
  selector: "app-add-question",
  templateUrl: "./add-question.component.html",
  styleUrls: ["./add-question.component.scss"],
})
export class AddQuestionComponent implements OnInit {
  @Input() questionId: string;

  public Editor = ClassicEditor;
  private subjectId: string;
  public questionData: QuestionData;
  private allTopics: any[] = [];
  private allTopicNames: string[] = [];
  private selectedTopics: string[] = [];
  private filteredTopics: Observable<string[]>;
  private selectable = true;
  private removable = true;
  private addOnBlur = true;
  private loaded = false;
  public unsavedChanges = false;

  constructor(
    public admin: AdminService,
    public authService: AuthService,
    public dataService: DataServiceService,
    public notificationsService: NotificationsService
  ) {}

  load() {
    if (this.questionId) {
      this.admin.data.afs
        .collection("questions")
        .doc(this.questionId)
        .get()
        .subscribe((question_data) => {
          this.questionData = question_data.data() as QuestionData;
          this.questionData.parts.forEach((p) => {
            if (!p.status) {
              p.status = QuestionPartReviewStatus.review;
            }
          });
          this.subjectId = this.questionData.subject_key;
          this.loadTopics(this.subjectId);
          this.loaded = true;
        });
    }
  }

  async render() {
    if (this.questionId) {
      this.load();
    }
  }

  ngOnInit() {
    this.render();
  }

  ngOnChanges() {
    this.render();
  }

  loadTopics(subjectId) {
    this.admin.getSubjectTopics(subjectId).subscribe((topic_data) => {
      this.allTopics = [];
      this.allTopicNames = [];
      topic_data.forEach((topic) => {
        const data = topic.payload.doc.data() as Topic;
        if (data["level"] === 2) {
          this.allTopics.push(data);
          this.allTopicNames.push(data["topic"]);
        }
      });
      this.loadTopicsFromIds();
    });
  }

  updateSelectedTopics(event) {
    const topicValue = event.source.value;
    if (event.checked) {
      if (!this.selectedTopics.includes(topicValue)) {
        this.selectedTopics.push(topicValue);
      }
    } else {
      const index = this.selectedTopics.indexOf(topicValue);
      if (index > -1) {
        this.selectedTopics.splice(index, 1);
      }
    }
  }

  async saveQuestion() {
    let save = true;

    // Validate marks
    let sum = 0;
    this.questionData.parts.forEach((part) => {
      sum += part.marks;
    });
    if (sum != this.questionData.marks) {
      save = false;
      return this.notificationsService.snack(
        "NOT SAVED. Check sum of marks for sub-questions."
      );
    }

    // Make sure text has been added
    this.questionData.parts.forEach((part) => {
      if (part.memo_html === "" || part.question_html === "") {
        save = false;
        return this.notificationsService.snack(
          `NOT SAVED. Check question or memo text for part ${part.identifier}`
        );
      }
    });

    // Topics
    this.questionData.topics = this.getTopicIds();
    this.questionData.sub_question_count = this.questionData.parts.length;
    this.questionData.changesNotInProd = true;

    // // Add to Firestore
    if (save) {
      this.admin.data.afs
        .collection("questions")
        .doc(this.questionId)
        .update(this.questionData)
        .then((d) => {
          this.notificationsService.snack("Data saved. Preview updated.");
          this.unsavedChanges = false;
        });
      this.updateTopicList(this.questionData);
    }
  }

  async updateTopicList(questions_data) {
    // 1. get all topics for subject
    let topics = await this.dataService.getSubjectTopicsLevelTwo(
      this.subjectId
    );

    // 2. check the question is added/exists in specific topics
    let topicsUpdated = this.addQuestionToTopic(topics, questions_data);

    // 3. remove question from topics where it does not exist
    topicsUpdated = this.removeQuestionFromWrongTopics(
      topicsUpdated,
      questions_data
    );
  }

  addQuestionToTopic(topicCollection, questionData) {
    const question_string = questionData["question_string"];
    let questionTopics = questionData["topics"];

    for (var i in questionTopics) {
      let questionTopic = questionTopics[i];

      // add question to topics collection
      if (!topicCollection[questionTopic].questions.includes(question_string)) {
        console.log(`updating topic ${questionTopic}`);
        topicCollection[questionTopic].questions.push(question_string);
        this.updateTopicsCollection(
          questionTopic,
          topicCollection[questionTopic]
        );
      }
    }
    return topicCollection;
  }

  removeQuestionFromWrongTopics(topicCollection, questionData) {
    const question_string = questionData["question_string"];
    let questionTopics = questionData["topics"];
    for (var topic in topicCollection) {
      // if topic does not include question topic we must remove the question_string
      if (!questionTopics.includes(topic)) {
        let index = topicCollection[topic].questions.indexOf(question_string);
        if (index != -1) {
          console.log(`removing from topic ${topic}`);
          topicCollection[topic].questions.splice(index, 1); // remove question_string
          this.updateTopicsCollection(topic, topicCollection[topic]);
        }
      }
    }
    return topicCollection;
  }

  updateTopicsCollection(topic, topicCollection) {
    this.admin.data.afs
      .collection("topics")
      .doc(topic)
      .update(topicCollection)
      .then((success) => {
        console.log(`successfully updated ${topic}`);
      });
  }

  removeQuestion() {
    this.admin.data.afs
      .collection("questions")
      .doc(this.questionId)
      .delete()
      .then((d) => {
        this.notificationsService.snack("Question deleted");
        this.admin.router.navigate(["/papers"]);
      });
  }

  getTopicIds() {
    const topicIds = [];
    this.selectedTopics.forEach((topic) => {
      this.allTopics.forEach((topicData) => {
        if (topicData.topic === topic) {
          // @ts-ignore
          topicIds.push(topicData.key);
        }
      });
    });
    return topicIds;
  }

  loadTopicsFromIds() {
    this.selectedTopics = [];
    this.questionData.topics.forEach((topicId) => {
      this.allTopics.forEach((topicData) => {
        if (topicData.key === topicId) {
          this.selectedTopics.push(topicData.topic);
        }
      });
    });
  }

  public onQuestionChange({ editor }: ChangeEvent, part) {
    const data = editor.getData();
    this.questionData.parts[part].question_html = data;
    this.unsavedChanges = true;
  }

  public onMemoChange({ editor }: ChangeEvent, part) {
    const data = editor.getData();
    this.questionData.parts[part].memo_html = data;
    this.unsavedChanges = true;
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  markPartAs(identifier, status: QuestionPartReviewStatus) {
    for (let i = 0; i < this.questionData.parts.length; i++) {
      if (this.questionData.parts[i].identifier === identifier) {
        this.questionData.parts[i] = {
          ...this.questionData.parts[i],
          status: status,
        };
      }
    }
    console.log(this.questionData.parts.map((p) => p.status));
    this.admin.data.afs
      .collection("questions")
      .doc(this.questionId)
      .update(this.questionData)
      .then((d) => {
        this.notificationsService.snack("Part status saved.");
      });
  }
}
