import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { MembershipService } from "../../services/membership/membership.service";
import { AngularFireStorage } from "@angular/fire/storage";
import { NotificationsService } from "../../services/notifications/notifications.service";
import { Invoice, Platforms, SlackChannels } from "../../../../types/types";
import { POP } from "../../../../types/types";
import { environment } from "../../../../types/environments/environment";

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
})
export class AccountComponent implements OnInit {
  private uploadDocumentKey = "";
  private platform: Platforms | undefined = undefined;
  constructor(
    public authService: AuthService,
    public membershipService: MembershipService,
    public storage: AngularFireStorage,
    public notifications: NotificationsService
  ) {
    this.platform = environment.platform;
  }

  async uploadPOP(event) {
    const file = event.target.files[0];
    const extension = file.type;
    if (extension == "application/pdf") {
      const filePath = `POP/${
        this.authService.userInfo.uid
      }/${new Date().getTime()}.pdf`;
      this.authService.fs
        .collection("invoices")
        .doc(this.uploadDocumentKey)
        .update({
          POP: POP.uploading,
        });
      this.storage.upload(filePath, file).then(async (d) => {
        const POPURL = await d.ref.getDownloadURL();
        this.notifications.snack("File uploaded");
        this.authService.fs
          .collection("invoices")
          .doc(this.uploadDocumentKey)
          .update({
            POP: POP.uploaded,
            POPFilePath: filePath,
            POPURL,
            autoApprovalStarted: false,
          })
          .catch((e) => {
            this.notifications.snack("Upload failed");
            this.authService.fs
              .collection("invoices")
              .doc(this.uploadDocumentKey)
              .update({
                POP: POP.failed,
              });
          });
        this.notifications.postToSlack(`POP uploaded`, SlackChannels.payments);
      });
    } else {
      this.notifications.snack("PDF format required");
    }
  }

  ngOnInit() {}

  cancelInvoice(invoice: Invoice) {
    this.notifications
      .snackConfirm("Confirm cancel?", 3000)
      .onAction()
      .subscribe(() => {
        this.authService.fs
          .collection("invoices")
          .doc(invoice.id)
          .update({ POP: POP.cancelled })
          .then((d) => {
            this.notifications.snack(`Invoice ${invoice.invoiceNo} cancelled`);
          });
      });
  }

  openInvoice(invoice: Invoice) {
    window.open(invoice.invoice_download_url, "_blank");
  }
}
