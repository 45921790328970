import { Component, OnInit, ElementRef } from "@angular/core";
import { Location } from "@angular/common";
import { AuthService } from "../../services/auth/auth.service";
import { MembershipService } from "../../services/membership/membership.service";
import { MarkerService } from "../../services/marker/marker.service";
import { environment } from "../../../../types/environments/environment";
import { Router } from "@angular/router"; // Import ActivatedRoute

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  private toggleButton: any;
  private sidebarVisible: boolean;
  private navbarVisible: boolean = false;
  public appName = environment.appName;
  constructor(
    public location: Location,
    private element: ElementRef,
    public authService: AuthService,
    public membershipService: MembershipService,
    public markerService: MarkerService,
    private router: Router
  ) {
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event.constructor.name === "NavigationEnd") {
        this.navbarVisible = this.location.path() !== "/landing";
      }
    });
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName("html")[0];
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);
    html.classList.add("nav-open");
    this.sidebarVisible = true;
  }
  sidebarClose() {
    const html = document.getElementsByTagName("html")[0];
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
}
