import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { environment } from "../../../../types/environments/environment";

@Component({
  selector: "app-terms-and-conditions-pop",
  templateUrl: "./terms-and-conditions-pop.component.html",
  styleUrls: ["./terms-and-conditions-pop.component.scss"],
})
export class TermsAndConditionsPopComponent implements OnInit {
  appName = environment.appName;

  constructor(
    private dialogRef: MatDialogRef<TermsAndConditionsPopComponent>
  ) {}

  ngOnInit() {}

  close(termsAccepted: boolean) {
    this.dialogRef.close(termsAccepted);
  }
}
