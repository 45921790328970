import { Timestamp } from "@firebase/firestore-types";

export enum stage {
  DEV = "dev",
  PROD = "prod",
}

export enum Platforms {
  act = "ACT",
  law = "LAW",
  ca = "CA",
}

export const DomainNames = {
  [Platforms.act]: "actuarial science",
  [Platforms.law]: "law",
  [Platforms.ca]: "accounting",
};

export const PlatformNames = {
  [Platforms.act]: "ThinkActuary",
  [Platforms.law]: "ThinkLawyer",
  [Platforms.ca]: "ThinkCA",
};

export const emailAddresses = {
  [Platforms.act]: "alice@thinkactuary.com",
  [Platforms.law]: "sanan@thinklawyer.co.za",
};

export enum ProfessionalBodies {
  ASSA = "ASSA",
  IFOA = "IFOA",
  ATTORNEY = "ATTORNEY",
  ADVOCATE = "ADVOCATE",
  SAICA = "SAICA",
}

export const ProfessionalBodyMapping = {
  [Platforms.act]: [ProfessionalBodies.ASSA, ProfessionalBodies.IFOA],
  [Platforms.law]: [ProfessionalBodies.ATTORNEY, ProfessionalBodies.ADVOCATE],
  [Platforms.ca]: [ProfessionalBodies.SAICA],
};

export enum ProfessionalBodyDescriptions {
  ASSA = "Actuarial Society of South Africa",
  IFOA = "Institute and Faculty of Actuaries",
  ADVOCATE = "Advocate",
  ATTORNEY = "Attorney",
  SAICA = "South African Institute of Chartered Accountants",
}

export interface SignIn {
  uid: string;
  email: string;
  displayName: string;
  chosenName: string;
  processed: boolean;
  timestamp: number;
  usingEmailAndPassword: boolean;
  professionalBody?: ProfessionalBodies;
  platform: Platforms;
}

export interface User {
  email: string;
  credits: number;
  name: string;
  uid: string;
  showInstructions: boolean;
  createDate: number;
  tcsAccepted: boolean;
  emailVerified?: boolean;
  disabled?: boolean;
  professionalBody?: ProfessionalBodies;
  aiRecIntroComplete?: boolean;
  currentSubjectCode?: string;
  platform?: Platforms;
}

export enum Packages {
  trial = "trial",
  basic = "basic",
  standard = "standard",
  premium = "premium",
  tuition = "tuition",
}

export interface MembershipConfigs {
  [Packages.trial]?: Package;
  [Packages.basic]?: Package;
  [Packages.standard]?: Package;
  [Packages.premium]?: Package;
}

export enum ReviewType {
  assignment = "assignment",
  actuary = "actuary",
  self = "self",
  back = "back",
  peer = "peer",
  pending = "pending",
}

export enum ExamStates {
  STOPPED = "STOPPED",
  NON_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  SUBMITTED_FOR_REVIEW = "SUBMITTED_FOR_REVIEW",
  COMPLETED = "COMPLETED",
}

export interface ExamReview {
  id: string;
  // Temp property
  examAttemptKey?: string;
  user: string;
  email: string;
  subjectId: string;
  paper: string;
  paperId: string;
  timestampSubmit: number;
  timestampCreated: number;
  timestampSaved: number;
  timestampStarted?: number;
  timestampFinished?: number;
  state: ExamStates;
  reviewType: ReviewType;
  timeLeft: number;
  timeTotal: number;
  reviews: ExamQuestionReview[];
  marksPossible: number;
  marksObtained?: number;
  source: string;
}

export enum ReviewGrade {
  P_PLUS = "P+",
  P = "P",
  FAIL_A = "FA",
  FAIL_B = "FB",
  FAIL_C = "FC",
  FAIL_D = "FD",
}

export const ReviewGrades = [
  {
    grade: ReviewGrade.P_PLUS,
    description: "Excellent Pass",
  },
  {
    grade: ReviewGrade.P,
    description: "Pass",
  },
  {
    grade: ReviewGrade.FAIL_A,
    description: "Fail - Minor Improvement Needed",
  },
  {
    grade: ReviewGrade.FAIL_B,
    description: "Fail - Significant Improvement Needed",
  },
  {
    grade: ReviewGrade.FAIL_C,
    description: "Fail - Major Improvement Needed",
  },
  {
    grade: ReviewGrade.FAIL_D,
    description: "Fail - Substantial Improvement Required",
  },
];

export interface Review {
  id?: string;
  question_html?: string;
  memo_html?: string;
  aiAssessment?: QuestionAssessment;
  /** User's uid */
  user: string;
  email: string;
  subject: string;
  subjectName: string;
  subjectId: string;
  source: string;
  parts?: QuestionPart[];
  partsToBeMarked: QuestionPart[];
  question_identifier: string;
  questionKey: string;
  attemptText: string;
  attemptTextReviewed?: string;
  reviewed: boolean;
  state: number;
  timestamp_submit: number;
  time_submitted: number;
  /** Reviewer's uid */
  reviewed_by: string | Boolean;
  /** Reviewer's email */
  reviewed_by_email?: string;
  email_underway: boolean;
  email_completed: boolean;
  /** The number of marks possible */
  mark: number;
  /** Marks obtained / marks possible */
  score: number;
  /** The number of marks obtained */
  score_mark: number;
  /** The grade of the attempt */
  grade?: ReviewGrade;
  reviewType: ReviewType;
  /** Reviewer's comments on the attempt */
  reviewer_comments_html: string;
  /** Feedback score given by user */
  feedback_quality: string;
  /** Marker feedback given by AI */
  markerFeedback?: MarkerFeedback;
  structure_score: QuantitativeAssessmentScores;
  allocatedMarkerUid?: string;
  allocatedMarkerEmail?: string;
  assignedToMarkerAt?: number;
  reminderSent?: boolean;
  state_timestamp?: number;
  questionTrailer?: string;
  questionNumber?: number;
  /** Message telling the marker how long until the review gets unlocked  */
  timeLeft?: string;
  /** List of actions taken on the review */
  log?: ReviewLogItem[];
  /** Link to Google sheet used as part of answer */
  sheetLink?: string;
  /** The number of credits used on the review */
  creditsUsed: number;
  /** cost of the review */
  costForReview?: number;
  costPerCredit?: number;
}

export interface ReviewCoherence {
  review: string;
  coherent: boolean;
  comment: string;
}

export interface MarkerFeedback {
  review: string; // Ai generated
  grammar: string; // Ai generated
  structure: string; // Ai generated
  conciseness: string; // Ai generated
  guidance: string; // Ai generated
  feedback: string; // Ai generated
  overall: number; // Ai generated
  timestamp: number; // Set
  reviewId: string; // Set
  reviewType: ReviewType; // Set
}

export interface QuestionPartQuantitativeAssessment {
  assessmentList: string;
  score: number;
  marks: number;
}
export interface QuestionPartFeedback {
  identifier: string;
  quantitativeAssessment: QuestionPartQuantitativeAssessment;
  coverage: string;
  conciseness: string;
  grammar: string;
  ideaGeneration: string;
  keyIdeas: string;
  informationUse: string;
  substantiation: string;
  structure: string;
  advice: string;
  result: string;
  score: string;
  marks: number;
  attempt?: string;
}

export interface OverallQuestionFeedback {
  coverage: string;
  conciseness: string;
  grammar: string;
  ideaGeneration: string;
  keyIdeas: string;
  informationUse: string;
  substantiation: string;
  structure: string;
  advice: string;
  result: string;
  score: string;
  marks: number;
  quantitative: QuantitativeAssessmentScores;
}

export interface QuestionAssessment {
  partAssessments: QuestionPartFeedback[];
  overallAssessment: OverallQuestionFeedback;
  summary: string;
}

export interface LanguageModelTextOutput {
  result: string;
}

export interface LanguageModelBooleanOutput {
  result: boolean;
}

export interface ExamQuestionReview extends Review {
  /** The review's key in the "reviews" collection for cross-referencing purposes */
  reviewCollectionKey?: string;
  /** Indicates that the reviews belongs to an exam - can be used to calculate exam performance */
  examReviewId?: string;
}

interface ReviewLogItem {
  timestamp: number;
  marker: string;
  description: string;
}

export interface QuantitativeAssessmentScores {
  key_ideas: number;
  info_used: number;
  concise: number;
  idea_generated: number;
}

export enum QuestionPartReviewStatus {
  review = "NEEDS_REVIEW",
  final = "FINAL",
  update = "UPDATE",
}

export interface QuestionPart {
  identifier: string;
  number: number;
  marks: number;
  memo_html?: string;
  question_html?: string;
  attempt?: string;
  assessment?: QuestionPartFeedback;
  status?: QuestionPartReviewStatus;
  topicsWithRelevanceScores?: TopicRecommendationOutput;
  recommendedTopics?: TopicRecommendation[];
}

export interface QuestionData {
  /** Question document key in Firestore */
  key: string;
  /** Question number */
  number: number;
  /** Paper's identifier e.g. "201906_1" */
  paper: string;
  /** The paper key as found on subjects/subject_key/papers/paper_key */
  paper_key: string;
  /** Question & memo data by part */
  parts: QuestionPart[];
  /** Body that set the paper */
  prof_body: ProfessionalBodies;
  /** Question's identifier e.g. "201906_1/2" */
  question_string: string;
  /** Whether the question has been reviewed */
  reviewed: boolean;
  /** Description of the paper from which the question comes */
  source: string;
  /** Number of sub-questions */
  sub_question_count: number;
  /** The subject code as found on subjects/subject_key.code */
  subject: string;
  /** The subject key as found on subjects/subject_key */
  subject_key: string;
  /** The subject name as found on subjects/subject_key.name */
  subject_name: string;
  /** Chronological identifier for the question */
  timestamp: number;
  /** List of topics the question belongs to */
  topics: string[];
  /** List of recommended topics the question covers by */
  recommendedTopics?: TopicRecommendation[];
  /** Total number of marks */
  marks: number;
  /** Combination of subject_key and paper_key */
  subject_paper?: string;
  /** deprecated */
  question_marks?: number;
  /** deprecated */
  question_number?: number;
  /** Paper's URL */
  paperLink?: string;
  /** Memo's URL  */
  memoLink?: string;
  format_version?: number;
  attempted?: boolean;
  question_html_display?: any;
  memo_html?: any;
  question_html?: any;
  flagged?: boolean;
  performance?: number;
  changesNotInProd?: boolean;
  assignment?: Assignment;
  usage: QuestionUsage;
  /** AI generated info sometimes added */
  recommendationMeta?: SelectedQuestion;
  /** to track down question only saved but not submitted */
  savedButNotComplete?: boolean;
  paperId: string;
  /** The user's last score on the question between 0% and 100% */
  scoreAchieved?: string;
  /** Instructions for the paper updater */
  editInstructions?: string;
}

export interface QuestionUsage {
  /** Average score on the question across all review types */
  avgScore: number;
  /** Number of reviews on which average score was calculated */
  reviewCount: number;
}

export interface Question {
  link: string;
  prof_body: string;
  source: string;
  topic: string[];
}
export interface SelectedQuestion {
  questionId: string;
  questionSource: string;
  questionNumber: number;
  decisionRationale: string;
}

export interface SelectedTopic {
  topicId: string;
  topicName: string;
  decisionRationale: string;
}

export interface Advertiser {
  active: boolean;
  name: string;
  description: string;
  email: string;
  subject: string;
  website: string;
  popupDescription: string;
}

export interface SyllabusPdf {
  url: string | undefined;
  path: string | undefined;
  instructions: string | undefined;
}

export interface SubjectData {
  id?: string;
  active: boolean;
  code: string;
  codeDisplay: string;
  key: string;
  name: string;
  rank: number;
  creditsPerMark: number;
  reviews: {
    actuary: boolean;
    peer: boolean;
  };
  professionalBody: ProfessionalBodies;
  send_notifications: boolean;
  platform: Platforms;
  advertiser?: Advertiser;
  featured?: boolean;
  markers?: SubjectMarkerMapping[];
  timestamp?: number; // To be removed from database
  date?: Date; // To be removed from database
  examDate?: Timestamp; // To be removed from database
  /** Whether flash cards is active  */
  flashcards?: boolean;
  /** Whether MCQ is active  */
  mcq?: boolean;
  syllabus?: SyllabusPdf;
}

export interface SubjectMarkerMapping {
  active: boolean;
  lastAllocatedAt: number;
  subjectId: string;
  uid: string;
  marksCompleted6Months?: number;
}

export interface PaperMapQuestion {
  number: number;
  marks: number;
  subParts: number;
  parts: Record<string, any>;
  partsMarkTotal: number;
  dataIntegrityCheck: boolean;
  questionTheme: string;
}
export interface PaperMap {
  questions: PaperMapQuestion[];
  questionCount: number;
  totalMarks: number;
  issues: string[];
}

export interface Paper {
  id: string; // Format: f106_202206_1
  paperIdString: string; // Format: 202206_1
  year: number;
  month: number;
  session: number;
  paperNumber: number;
  subjectId: string;
  professionalBody: ProfessionalBodies;
  questionCount: number; // Example: 8
  totalMarks: number; // Example: 100
  source: string; // Example: Subject ST9 — Enterprise Risk Management Specialist Technical (April 2010)
  released: boolean;
  memoUrl?: string; // URL
  paperUrl?: string; // URL
  /** Temp field used by the paper management system */
  questionData?: QuestionData[];
  paperContent?: string;
  memoContent?: string;
  do?: RedoQuestion[];
  path?: string;
  refresh?: boolean;
  paperMap?: PaperMap;
  /** Guidance for the paper map added on the admin interface to allow the system to allow for errors/inconsistencies */
  paperMapGuidance?: string;
}

export enum Tasks {
  invoicePaid = "invoice_paid",
  setPackage = "setPackage",
  credits = "credits",
  newReviewCreated = "review_created",
  reviewComplete = "review_complete",
  selfReviewComplete = "self_review_complete",
  actuaryReviewRequested = "review_requested",
  actuaryReviewComplete = "actuary_review_complete",
  peerReviewRequested = "peer_review_requested",
  peerReviewComplete = "peer_review_complete",
  assignmentReviewComplete = "assignment_review_complete",
  credits_quote = "credits_quote",
  tuition_course_participant = "tuition_course_requirements",
  tuition_course_participant_reminder = "tuition_course_reminder",
  create_sheet = "create_sheet",
  generateFeedbackForMarker = "generate_marker_feedback",
  refreshAnalytics = "refresh_analytics",
  generateAiRecommendation = "generate_ai_recommendation",
  slack = "slack",
  topicCreationWorkflow = "topic_creation_workflow",
  paperCreationWorkflow = "paper_creation_workflow",
  paperUpdateWorkflow = "paper_update_workflow",
  mcqGenerationWorkflow = "mcq_generation_workflow",
}

export interface Task {
  uid: string;
  type: Tasks;
  platform: Platforms;
  id?: string;
  review?: Review;
}

export interface SlackTask extends Task {
  message: string;
  channel: SlackChannels;
}

export interface InvoicePaidTask extends Task {
  invoiceId: string;
}

export interface SetPackageTask extends Task {
  uid: string;
  packageDescription: Packages;
  verified: boolean;
  professionalBody?: ProfessionalBodies;
}

export interface SetCreditsTask extends Task {
  uid: string;
  credits: number;
  description: string;
  sendEmail: boolean;
}

export interface CreateSheetTask extends Task {
  sheetTitle: string;
  uid: string;
}

export interface RefreshAnalyticsTask extends Task {
  subjectId: string;
  userId: string;
}

export interface CreateAiRecommendationTask extends RefreshAnalyticsTask {
  topicId: string;
}

export interface NewReviewTask extends Task {
  reviewKey: string;
}

export interface WorkflowEvent extends Task {
  workflowId?: string;
}

export interface AddSyllabusWorkflowEvent extends WorkflowEvent {
  subjectId: string;
}

export interface AddPaperEvent extends WorkflowEvent {
  subjectId: string;
  paperId?: string;
  paperUrl?: string;
  update: boolean;
  memoUrl?: string;
  paper?: Paper;
}

export interface PaperUpdateWorkflowTask extends Task {
  paperId: string;
}

export interface McqGenerationWorkflowTask extends Task {
  subjectId: string;
}

export interface WorkflowState {
  workflowId: string;
  state: string;
  timestamp: number;
}

export interface RedoQuestion {
  questionNumber: number;
  instruction: string;
}

export enum TopicCreationWorkflowProgress {
  STARTED = "STARTED",
  SCAFFOLDING = "SCAFFOLDING",
  ENRICHING = "ENRICHING",
  UPLOADING = "UPLOADING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export interface Topic {
  /* Subject's name */
  exam: string;
  /* Subject's database identifier */
  exam_key: string;
  /** Topic description */
  topic: string;
  /**
   * Topic's database identifier:
   * Level 1 (topic): "exam_key" + "_" + topicDescription
   * Level 2 (sub-topic): "exam_key" + "_" + level1TopicDescription (lower case) + "_" + level2TopicDescription(lower case)
   */
  key: string;
  /** Same as key */
  topic_key?: string;
  /** 1=topic, 2=sub-topic */
  level: number;
  /** List of question strings */
  questions: any[];
  /** List of question keys */
  question_keys: any[];
  questions_complete?: any[];
  /** What the topic is about */
  description: string;
  syllabus_keys?: any[];
  /** Topic's index */
  index?: number;
  /** HTML list of objectives */
  objectives?: string;
  /** HTML list of concepts */
  concepts?: string; // HTML list
  /** HTML list of skills */
  skills?: string; // HTML list
  /** Cognitive level from Bloom's taxonomy */
  cognitiveLevel?: CognitiveLevel;
  /** History of questions and question keys added to the topic */
  mappingHistory?: {
    questions: string[];
    question_keys: string[];
    usedUntil: number;
  }[];
}

export interface TopicNode {
  name: string;
  key: string;
  children?: TopicNode[];
  questions: number;
  questions_complete?: number;
  questions_ref?: any[];
}

export interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  key: string;
  questions: number;
}

export interface PaperTopics {
  topics: any[];
  year: number;
  month: number;
  paper_number: number;
  paper_ref: string;
  question_number: number;
  question_ref: any;
  attempted: boolean;
  performance: number;
  maxMarkPossible: number;
}

export interface TuitionStudent {
  name: string;
  surname: string;
  email: string;
  exam_key: string;
  session: number;
  year: number;
  courses: string[];
  code: string;
  nameInUsersCollection: string;
  send_notifications: boolean;
  signedUpEmail: string;
  uid: string;
  lastAllocatedAt: number;
  preSignUpWelcomeMail?: boolean;
  allowComs?: boolean;
  documentId?: string;
  id?: string;
  reporting?: TuitionStudentReporting;
  membershipNumber?: string;
}
export interface TuitionStudentReporting {
  email: string;
  membershipNumber: string;
  signedUp: boolean;
  signedUpEmail: string;
  uid: string;
  subjectCode: string;
  year: number;
  session: number;
  actuary: TotalMarks;
  self: TotalMarks;
  peer: TotalMarks;
  date: string;
  courseList?: string[];
}

export interface WeekUsageReport {
  subjectCode: string;
  year: number;
  session: number;
  week: number;
  actuary: TotalMarks;
  self: TotalMarks;
  peer: TotalMarks;
}

export interface TotalMarks {
  marksCompleted: number;
  reviewsCompleted: number;
  averageScore: number;
  reviewType: ReviewType;
}

export enum SlackChannels {
  general = "#general",
  reviews = "#reviews",
  analytics = "#analytics",
  questionIssues = "#question-issues",
  logins = "#logins",
  memberships = "#memberships",
  exceptions = "#exceptions",
  reviewManagement = "#review-management",
  invoicing = "#invoicing",
  email = "#email",
  pages = "#pages",
  payments = "#payments",
  tuitionWeeklyReminders = "#weekly_reminders",
  sheets = "#sheets",
  markerFeedbackIssues = "#marker-quality-issue",
  markerFeedbackAssessments = "#marker-quality-assessments",
  jobBoardInterest = "#job-board-interest",
  aiAssessments = "#ai-question-assessments",
  drip = "#drip",
  ai = "#ai",
  advertisers = "#advertisers",
  podcasts = "#podcasts",
  content = "#content",
  tuitionRegistrations = "#tuition-registrations",
}

export interface Package {
  /** Determines how long the package will last
   * Only used for Trial.
   * For paid packages the server determines to when they run.
   */
  day_limit: number;
  mark_credits: number;
  membership_descr: Packages;
  prices: {
    [ProfessionalBodies.ASSA]: Price;
    [ProfessionalBodies.IFOA]: Price;
    [ProfessionalBodies.ADVOCATE]: Price;
    [ProfessionalBodies.ATTORNEY]: Price;
    [ProfessionalBodies.SAICA]: Price;
  };
}

interface Price {
  units: number;
  currency: Currency;
  productUrl: string;
}

export enum Currency {
  ZAR = "ZAR",
  GBP = "GBP",
}

export interface SelectedPackage extends Package {
  timestamp: number;
  uid: string;
  verified: boolean;
  user_email: string;
  type: string;
  timeCreated: number;
  cost: number;
  daysRemaining?: number;
  professionalBody?: ProfessionalBodies;
  currency?: Currency;
}

export interface ValuesPerSubject {
  [key: string]: {
    [reviewType: string]: number;
  };
}

export interface ValuesByChannel {
  total: ValuesPerSubject;
  tuition: ValuesPerSubject;
  nonTuition: ValuesPerSubject;
}

export interface UserAnalytics extends SelectedPackage {
  reviews: Review[];
  marks: ValuesByChannel;
}

export enum ExamSessions {
  First = 1,
  Second = 2,
}

export enum InvoiceType {
  membership = "membership_selected",
  credits = "credits_quote",
}

export interface GenerateInvoice {
  uid: string;
  entityName: string;
  customer: string[];
  items: InvoiceItem[];
  type: InvoiceType;
  //** The id of the membership or credits document the invoice is for */
  refDocumentId: string;
  verified: boolean;
  timestamp: number;
  user_email: string;
  email: string;
  displayName: string;
  platform: Platforms;
  cost?: number;
  localPath?: string;
  invoiceNo?: string;
  invoice_download_url?: string;
  POP?: string;
  POPFilePath?: string;
  POPURL?: string;
  added_to_account?: boolean;
  /** Whether the auto approval task has been kicked off
   * When a POP is uploaded, this is set to false.
   * The server listens for invoices with POP=uploaded and autoApprovalStarted=false.
   * The server kicks off the auto approval task and then sets autoApprovalStarted=true.
   */
  autoApprovalStarted?: boolean;
}

export interface Invoice extends GenerateInvoice {
  id: string;
}

export interface InvoiceItem {
  description: string;
  quantity: number;
  price: number;
}
export interface InvoiceData {
  entityName: string;
  registrationNumber: string;
  invoiceNo: string;
  currency: string;
  seller: string[];
  customer: string[];
  bankName: string;
  bankAccountNumber: string;
  items: InvoiceItem[];
  vatRate: number;
  platform: Platforms;
}

export interface ExamReadiness {
  uid: string;
  score: number;
  calculationTimestamp: number;
  subjectId: string;
}

export interface FeedBackQuality {
  uid: string;
  score: number;
  calculationTimestamp: number;
  subjectId: string;
}

export interface SummaryStats {
  selfReviewQuestions: number;
  selfReviewMaxMarks: number;
  selfReviewScoredMarks: number;
  peerReviewQuestions: number;
  peerReviewMaxMarks: number;
  peerReviewScoredMarks: number;
  actuaryReviewQuestions: number;
  actuaryReviewMaxMarks: number;
  actuaryReviewScoredMarks: number;
}

export interface Fees {
  costPerCredit: number;
  costPerMark: number;
  creditsPerMark?: number;
  subjectCodeDisplay?: string;
}

export interface MarkerStats {
  marksComplete: number;
  creditsEarned: number;
  marksGiven: number;
  averageMark: number;
  questionsMarked: number;
  fees: number;
  feePerMark?: number;
  feedbackQuality: number;
}
export interface Marker {
  active: Boolean;
  lastAllocatedAt: number;
  subjectId: string;
  uid: string;
  id?: string;
}

export interface MarkerReport {
  totalReviews: number;
  totalMarks: number;
  totalGiven: number;
  totalCredits: number;
  totalEarnings: number;
  avgEarningsPerMark: string;
  year: number;
  session: number;
  subjects: string;
  numberOfQuestions: number;
  email: string;
  score: string;
  feedbackScore: string;
  sessionId?: string;
  lastUpdated: number;
}

export enum POP {
  failed = "FAILED",
  uploading = "UPLOADING",
  uploaded = "UPLOADED",
  accepted = "ACCEPTED",
  declined = "DECLINED",
  cancelled = "CANCELLED",
}

export interface PopAssessment {
  valid: boolean;
  decisionRationale: string;
}

export interface SelectExamOutput {
  month: number;
  year: number;
  number: number;
  paper: string;
  subjectId: string;
  examAttemptKey?: string;
}
export interface Credit {
  change: number;
  credits: number;
  description: string;
  time: number;
  uid: string;
}

export interface Sheet {
  title: string;
  uid: string;
  fileId: string;
  link: string;
  created: number;
  reviewId?: string;
}

export interface JobBoardInterest {
  uid: string;
  userEmail?: string;
  timeCreated: number;
  timeCompleted: number;
  interest?: boolean;
}

export interface Assignment {
  dueDate: number;
  questionKey: string;
  reviewerUid: string;
  subjectKey: string;
  examSession: string;
  active: boolean;
}

export interface ExamCandidate {
  allowComs: boolean;
  code: string;
  courses: string[];
  email: string;
  exam_key: string;
  id: string;
  lastAllocatedAt: number;
  lastNotifiedData: number;
  membershipNumber: string;
  name: string;
  nameInUsersCollection: string;
  preSignUpWelcomeEmail: boolean;
  raw: any;
  send_notifications: boolean;
  session: number;
  signedUpEmail: string;
  surname: string;
  uid: string;
  year: number;
}

export interface AssignmentResult {
  uid: string;
  questionKey: string;
  score: string;
}

export interface ReviewTrainingData {
  reviewId: string;
  questionId: string;
  memorandum: string;
  attempt: string;
  partsToBeMarked: QuestionPart[];
  marksAwarded: number;
  marksPossible: number;
  scores: QuantitativeAssessmentScores;
  assessment: string;
  subject: string;
}

export enum GPTModel {
  o1Mini = "o1-mini",
  o1 = "o1-preview",
  GPT4o = "gpt-4o-2024-08-06",
  GPT4oMini = "gpt-4o-mini",
  MarkerA311 = "ft:gpt-4o-2024-08-06:thinkactuary:actuarial-a311:9zSFMZfd",
  MarkerF102 = "ft:gpt-4o-2024-08-06:thinkactuary:actuarial-f102:9zSAKs5G",
}

export enum AnthropicModel {
  Claude35Sonnet = "claude-3-5-sonnet-20240620",
}

export enum AiModels {
  GPT4o = "chatgpt-4o-latest",
  GPT4oMini = "gpt-4o-mini",
  MarkerA311 = "ft:gpt-4o-2024-08-06:thinkactuary:actuarial-a311:9zSFMZfd",
  MarkerF102 = "ft:gpt-4o-2024-08-06:thinkactuary:actuarial-f102:9zSAKs5G",
  Claude35Sonnet = "claude-3-5-sonnet-20240620",
}

// Analytics types
export interface QuestionStats {
  questions: number;
  maxMarks: number;
  scoredMarks: number;
  avgScore: number;
}

export interface PerformanceByReviewType {
  actuary: QuestionStats;
  self: QuestionStats;
  peer: QuestionStats;
  assignment: QuestionStats;
}

export interface TopicWithImportance extends Topic {
  questionCount: number;
  representation: number;
  importance: number;
}

export interface TopicWithImportanceAndCoverage extends TopicWithImportance {
  questionsAttempted: string[];
  questionsNotAttempted: string[];
  maxMarks: number;
  achievedMarks: number;
  avgScore: number;
  numberQuestionsAttempted: number;
  coverage: number;
}

export interface AnalyticsContext {
  topicCoverageLevel1: TopicWithImportanceAndCoverage[];
  topicCoverageLevel2: TopicWithImportanceAndCoverage[];
  performanceByReviewType: PerformanceByReviewType;
  lastQuestionTimestamp?: Timestamp;
  marksOverTime: ReviewScores[];
}

export enum DesktopStates {
  waiting = "waiting",
  creating = "creating",
  refreshing = "refreshing",
  done = "done",
  failed = "failed",
}

// AI Interfaces
export interface SelectedQuestion {
  number: number;
  questionId: string;
  questionPaperMonth: string;
  questionPaperYear: string;
  questionNumber: number;
  decisionRationale: string;
  questionMarks: number;
}

export interface QuestionRecommendation {
  reasoning: string;
  advice: string;
  questions: SelectedQuestion[];
}

export interface ReviewScores {
  score: number;
  timestamp: number;
}

export interface Desktop {
  userId: string;
  subjectId: string;
  aiEnabled: boolean;
  smartAi: boolean;
  state: DesktopStates;
  /** Status of the exam question selector */
  status: string;
  /** Status of the mcq selector */
  mcqStatus?: string;
  /** Status of the flash card selector */
  flashCardStatus?: string;
  analytics?: AnalyticsContext;
  focusTopicId?: string;
  focusSubTopicId?: string;
  questions?: SelectedQuestion[];
  questionSelectionReasoning?: string;
  questionSelectionAdvice: string;
  recommendedMainTopic?: SelectedTopic;
  recommendedSubTopic?: SelectedTopic;
  /** List of ordered flashcard keys */
  flashCards?: string[];
  /** List of ordered mcq keys */
  multipleChoiceQuestions?: string[];
}

export interface AttemptText {
  attemptText: string;
  timestamp: Timestamp;
  uid?: string;
  questionKey?: string;
}

export interface AiConfig {
  recommendations: boolean;
  sendAssessmentFeedbackEmails: boolean;
}

export interface BasePaperMeta {
  year: number;
  month: number;
  // 1 if Jan to June, 2 if July to Dec
  session: number;
  // 1 if no indication of multiple papers
  paperNumber: number;
  questionCount: number;
  totalMarks: number;
  source: string;
  paperContent: string;
  memoContent: string;
}

export interface PaperMeta extends BasePaperMeta {
  subjectId: string;
  paperIdString: string;
  redoQuestions?: RedoQuestion[];
  memoUrl?: string;
  paperUrl?: string;
}

enum Difficulty {
  unknown = "UNKNOWN",
  low = "LOW",
  mid = "MID",
  high = "HIGH",
}

export enum CognitiveLevel {
  REMEMBERING = "REMEMBERING",
  UNDERSTANDING = "UNDERSTANDING",
  APPLYING = "APPLYING",
  ANALYZING = "ANALYZING",
  EVALUATING = "EVALUATING",
  CREATING = "CREATING",
}

export interface Question {
  id: string;
  /** The subject to which the card belongs */
  subjectId: string;
  /** The question to which the card belongs */
  questionId: string;
  /** The topics the card covers */
  topicIds: string[];
  /** Theme */
  theme: string;
  /** Question string HTML */
  question: string;
  /** Card's target cognitive level */
  cognitiveLevel: CognitiveLevel;
  /** Difficulty rating. Initially UNKNOWN, set once experience arises */
  difficulty: Difficulty;
  /** Whether or not the card has been reviewed */
  reviewed: boolean;
  /** Issues with the card that needs to be addressed */
  issues: string[];
  /** Version of the system that created the card */
  version: string;
}

export interface Flashcard extends Question {
  /** Answer HTML string */
  answer: string;
}

export interface MultipleChoiceQuestionAnswer {
  /** Answer string */
  answer: string;
  /** Why it's wrong or confirmation that its right */
  feedbackIfSelected: string;
  /** Whether this is the right solution */
  correctChoice: boolean;
}

export interface MultipleChoiceQuestion extends Question {
  id: string;
  questionId: string;
  subjectId: string;
  topicIds: string[];
  question: string;
  answers: MultipleChoiceQuestionAnswer[];
  theme: string;
  cognitiveLevel: CognitiveLevel;
  workflowVersion: string;
  // What in the question needs to be fixed
  improvementNotes: string;
  // Whether the question needs to be redone based on the toFix field
  requiresRevision: boolean;
  // Whether the question has been released
  released: boolean;
}

export interface MultipleChoiceQuestionGenerationOutput {
  questions: MultipleChoiceQuestion[];
  planning: string;
}

export interface QuestionAttempt {
  userId: string;
  questionId: string;
  timestamp: number;
}

export enum FlashcardProficiency {
  unfamiliar = "UNFAMILIAR",
  somewhatFamiliar = "SOMEWHAT_FAMILIAR",
  mastered = "MASTERED",
}

export interface FlashcardAttempt extends QuestionAttempt {
  flashCardId: string;
  proficiency: FlashcardProficiency;
}

export interface MultipleChoiceQuestionAttempt extends QuestionAttempt {
  multipleChoiceQuestionId: string;
  /** True of right option was selected */
  proficiency: boolean;
}

export interface Podcast {
  subjectcode: [];
  created: any;
  creator: string;
  description: string;
  episode: number;
  season: number;
  title: string;
  link: string;
  id: string;
}

export interface TopicRecommendation {
  topic: string;
  key: string;
  reasoning: string;
  relevanceScore: number;
  relevantToQuestion: boolean;
}

export interface TopicRecommendationOutput {
  summary: string;
  topics: TopicRecommendation[];
}
