import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

import { AuthService } from "../../services/auth/auth.service";
import { DataServiceService } from "../../services/data/data-service.service";

@Component({
  selector: "app-questions",
  templateUrl: "./questions.component.html",
  styleUrls: ["./questions.component.scss"],
})
export class QuestionsComponent {
  constructor(
    private dialogRef: MatDialogRef<QuestionsComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    public authService: AuthService,
    private dataService: DataServiceService
  ) {}

  doQuestion(questionKey) {
    console.log(questionKey);
    this.dialogRef.close(questionKey);
  }

  closeDialog() {
    this.dialogRef.close(undefined);
  }
}
