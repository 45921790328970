import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {
  MarkDiaglogInput,
  MarkDiaglogOutput,
} from '../../dialogs/mark-confirm/mark-confirm.component';
import { SubQuestionSelection } from '../../components/sub-question-selection/sub-question-selection.component';
import { ReviewType } from '../../../../types/types';

@Component({
  selector: 'app-exam-pressure-end',
  templateUrl: './exam-pressure-end.component.html',
  styleUrls: ['./exam-pressure-end.component.scss'],
})
export class ExamPressureEndComponent {
  public subQuestionSelection: SubQuestionSelection = { selectedParts: [], marks: 0 };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MarkDiaglogInput,
    private dialogRef: MatDialogRef<ExamPressureEndComponent>
  ) {}
  close() {
    const markDiaglogOutput: MarkDiaglogOutput = {
      subQuestionSelection: this.subQuestionSelection,
      reviewType: this.data.reviewType,
    };
    this.dialogRef.close(markDiaglogOutput);
  }
  setSubQuestionSelection(subQuestionSelection: SubQuestionSelection) {
    this.subQuestionSelection = subQuestionSelection;
  }
}
