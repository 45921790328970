import { Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
@Component({
  selector: 'app-dialog-difficulty',
  templateUrl: './dialog-difficulty.component.html',
  styleUrls: ['./dialog-difficulty.component.scss'],
})
export class DialogDifficultyComponent {
  public path = '';

  constructor(private dialogRef: MatDialogRef<DialogDifficultyComponent>) {}

  submitRating(rating) {
    this.dialogRef.close(rating);
  }
}
