import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Advertiser, SlackChannels } from "../../../../types/types";
import { NotificationsService } from "../../services/notifications/notifications.service";
@Component({
  selector: "app-advertiser",
  templateUrl: "./advertiser.component.html",
  styleUrls: ["./advertiser.component.scss"],
})
export class AdvertiserPopupComponent {
  public advertiser: Advertiser;
  constructor(
    private dialogRef: MatDialogRef<AdvertiserPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data: Advertiser,
    private notificationsService: NotificationsService
  ) {
    this.advertiser = data;
  }
  closeDialog(action: string) {
    if (action === "visit") {
      if (this.advertiser) {
        this.notificationsService.postToSlack(
          `${this.advertiser.subject} -> ${this.advertiser.name} -> WEBSITE VISITED`,
          SlackChannels.advertisers
        );
        const url = new URL(this.advertiser.website);
        if (url.hostname !== window.location.hostname) {
          window.open(url.href, "_blank");
        } else {
          console.error("Cannot open a local URL in a new tab");
        }
      }
    }
    if (action === "email") {
      window.open(`mailto:${this.advertiser.email}`);
      this.notificationsService.postToSlack(
        `${this.advertiser.subject} -> ${this.advertiser.name} -> SEND EMAIL`,
        SlackChannels.advertisers
      );
    }
    if (action === "back") {
      this.notificationsService.postToSlack(
        `${this.advertiser.subject} -> ${this.advertiser.name} -> POPUP CLOSED`,
        SlackChannels.advertisers
      );
    }
    this.dialogRef.close();
  }
}
