import { Injectable } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { ExamCandidate } from "../../../../types/types";
import { Assignment, AssignmentResult } from "../../../../types/types";
@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  public loaded = false;
  public markerInfoLoaded = false;
  public assignmentDataLoaded = false;
  public assignments: Assignment[] = [];
  public assignmentCandidates: ExamCandidate[] = [];
  public assignmentCount = 0;

  constructor(public authService: AuthService) {}

  async getAssignmentData() {
    this.assignmentDataLoaded = false;
    try {
      this.assignments = await this.authService.getAssignments();
      if (this.assignments.length > 0) {
        const subjectKey = this.assignments[0]["subjectKey"];
        const session = this.assignments[0]["session"];
        const year = this.assignments[0]["year"];
        const subjectCd = await this.authService.getSubjectDisplayCodeFromKey(
          subjectKey
        );

        // Get the candidates
        this.assignmentCandidates =
          await this.authService.getAssignmentCandidates(
            subjectCd,
            session,
            year
          );
        const resultPromises: Promise<AssignmentResult>[] = [];

        // Get the attempt promises
        for (const candidate of this.assignmentCandidates) {
          for (const assignment of this.assignments) {
            const assReview = this.authService.getAssignmentReview(
              candidate["uid"],
              assignment.questionKey
            );
            resultPromises.push(assReview);
          }
        }

        // Wait for the data and add to candidates
        const results: AssignmentResult[] = await Promise.all(resultPromises);
        for (const candidate of this.assignmentCandidates) {
          candidate["assignments"] = [];
          for (const assignment of this.assignments) {
            const candidateAssignmentResult = results.filter(
              (r) =>
                r.uid === candidate.uid &&
                r.questionKey === assignment.questionKey
            )[0];
            candidate["assignments"].push(candidateAssignmentResult.score);
          }
        }
        this.assignmentCount = this.assignments.length;
      }
    } catch (error) {
      console.log("Could not get assignments");
    }

    this.assignmentDataLoaded = true;
  }
}
