import { Component } from '@angular/core';
import { MarkerService } from 'app/services/marker/marker.service';

@Component({
  selector: 'app-attempts',
  templateUrl: './attempts.component.html',
  styleUrls: ['./attempts.component.scss'],
})
export class AttemptsComponent {
  constructor(public markerService: MarkerService) {}
}
