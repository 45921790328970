import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { DataServiceService } from "../data/data-service.service";
import { AuthService } from "../auth/auth.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireDatabase } from "@angular/fire/database";
import { Invoice, SubjectData, WorkflowState } from "../../../../types/types";
import { environment } from "../../../../types/environments/environment";
@Injectable({
  providedIn: "root",
})
export class AdminService {
  private loaded = false;
  public subject: SubjectData;
  public question: string;
  public subjects: any[] = [];
  public markerSubjects: any[] = [];
  public notifications: any[] = [];
  private questions: any;
  private flaggedQuestions = 0;
  private reviewedQuestions = 0;
  private formattedQuestions = 0;
  public invoices: Invoice[] = [];
  public workflow: WorkflowState[] = [];

  constructor(
    public router: Router,
    public data: DataServiceService,
    private auth: AuthService,
    public fs: AngularFirestore,
    public db: AngularFireDatabase
  ) {
    this.auth.user.subscribe((user) => {
      if (user) {
        this.loadSubjects();
        this.loadNotifications();
        this.loadInvoices();
        this.subscribeToWorkflow();
      }
    });
  }

  loadNotifications() {
    this.data.afs
      .collection("notification_tasks", (ref) => ref.orderBy("date", "asc"))
      .get()
      .subscribe((notifications) => {
        this.notifications = notifications.docs.map((s) => s.data());
      });
  }

  loadSubjects() {
    this.data.afs
      .collection("subjects", (ref) =>
        ref.where("platform", "==", environment.platform)
      )
      .snapshotChanges()
      .subscribe((subjects) => {
        this.subjects = subjects.map((subject) => {
          const s = subject.payload.doc.data();
          s["id"] = subject.payload.doc.id;
          return s;
        });
      });
  }

  loadInvoices() {
    this.auth.fs
      .collection("invoices", (ref) =>
        ref.where("POP", "==", "UPLOADED").orderBy("timestamp", "desc")
      )
      .snapshotChanges()
      .subscribe((d) => {
        this.invoices = [];
        d.forEach((invoice) => {
          const invoiceData = invoice.payload.doc.data() as Invoice;
          invoiceData["id"] = invoice.payload.doc.id;
          if (invoiceData["POPURL"]) {
            this.invoices.push(invoiceData);
          }
        });
      });
  }

  getSubjectTopics(subjectId) {
    return this.data.afs
      .collection("topics", (ref) => ref.where("exam_key", "==", subjectId))
      .snapshotChanges();
  }

  fillQuestion(data) {
    const questionData: QuestionInterface = {
      paper: "[202206_2]",
      prof_body: "ASSA",
      marks: 0,
      number: 0,
      source: "",
      subject: "",
      subject_key: "",
      subject_name: "",
      timestamp: 0,
      topics: [],
      sub_question_count: 1,
      reviewed: false,
      format_version: 1,
      flagged: false,
      parts: [],
      question_string: "",
      ...data,
    };

    // Direct mappings
    for (const prop in questionData) {
      if (data.hasOwnProperty(prop)) {
        questionData[prop] = data[prop];
      }
    }

    // Load number
    if (data.hasOwnProperty("question_number")) {
      questionData["number"] = parseInt(data["question_number"]);
    }

    // Load marks
    if (data.hasOwnProperty("question_marks")) {
      questionData["marks"] = parseInt(data["question_marks"]);
    }
    if (data.marks > 0) {
      questionData["marks"] = parseInt(data["marks"]);
    }

    // Load parts
    if (data.hasOwnProperty("parts")) {
      questionData["parts"] = data["parts"];
    } else {
      questionData["parts"] = [
        {
          number: 1,
          identifier: "i",
          memo_html: data["memo_html"],
          question_html: data["question_html"],
          marks: data["question_marks"],
        },
      ];
    }

    // Map
    const map = {
      subject_name: "source",
    };
    for (const prop in map) {
      if (data.hasOwnProperty(map[prop])) {
        questionData[prop] = data[map[prop]];
      }
    }
    return questionData;
  }

  subscribeToWorkflow() {
    this.auth.fs
      .collection("workflow", (ref) =>
        ref.orderBy("timestamp", "desc").limit(50)
      )
      .snapshotChanges()
      .subscribe(
        (snapshot) => {
          this.workflow = snapshot.map(
            (doc) => doc.payload.doc.data() as WorkflowState
          );
        },
        (error) => {
          console.error("Error fetching workflow data: ", error);
        }
      );
  }
}

interface SubQuestionInterface {
  number: number;
  identifier: string;
  memo_html: string;
  question_html: string;
  marks: number;
}

export interface QuestionInterface {
  paper: string;
  prof_body: string;
  marks: number;
  number: number;
  source: string;
  subject: string;
  subject_key: string;
  subject_name: string;
  timestamp: number;
  topics: Array<string>;
  sub_question_count: number;
  parts: Array<SubQuestionInterface>;
  reviewed: boolean;
  format_version: number;
  flagged: boolean;
  question_string?: string;
}

export const dummyQuestion = {
  paper: "[202206_2]",
  prof_body: "ASSA",
  marks: 10,
  number: 1,
  source: "",
  subject: "",
  subject_key: "",
  subject_name: "",
  timestamp: 0,
  topics: [],
  sub_question_count: 1,
  reviewed: false,
  format_version: 1,
  flagged: false,
  parts: [],
};
