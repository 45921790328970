import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root',
})
export class AdminGuardService {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate() {
    if (!this.authService.admin) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
