import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { SubQuestionSelection } from '../../components/sub-question-selection/sub-question-selection.component';
import { QuestionPart, ReviewType, ExamReview } from '../../../../types/types';
import { AuthService } from '../../services/auth/auth.service';
@Component({
  selector: 'app-review-type-exam',
  templateUrl: './review-type-exam.component.html',
  styleUrls: ['./review-type-exam.component.scss'],
})
export class ReviewTypeExamComponent {
  public ReviewType = ReviewType;
  public subQuestionSelection: SubQuestionSelection = { selectedParts: [], marks: 0 };
  public reviewType: ReviewType = ReviewType.self;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReviewTypeExamDiaglogInput,
    private dialogRef: MatDialogRef<ReviewTypeExamComponent>,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public authService: AuthService
  ) {
    if (!data.beforeStarting) {
      this.reviewType = ReviewType.self;
    }
  }

  close(cancel: boolean) {
    if (cancel) {
      this.reviewType = ReviewType.back;
    }
    const markDiaglogOutput: ReviewTypeExamDiaglogOutput = {
      reviewType: this.reviewType,
    };
    this.dialogRef.close(markDiaglogOutput);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }
}

export interface ReviewTypeExamDiaglogInput {
  subject: string;
  credits: number;
  creditsPerMark: number;
  subjectId: string;
  marks?: number;
  acceptingPeerReviews?: boolean;
  tuitionCourseParticipant?: boolean;
  isAssignmentQuestion?: boolean;
  acceptingActuaryReviews?: boolean;
  reviewType?: ReviewType;
  beforeStarting: boolean;
  exam: ExamReview;
}

export interface ReviewTypeExamDiaglogOutput {
  reviewType: ReviewType;
}
