import { Component, OnInit, Input } from '@angular/core';
import { AnalyticsService } from '../../services/analytics/analytics.service';

@Component({
  selector: 'app-assignment-summary',
  templateUrl: './assignment-summary.component.html',
  styleUrls: ['./assignment-summary.component.scss'],
})
export class AssignmentSummaryComponent implements OnInit {
  constructor(public analyticsService: AnalyticsService) {}

  ngOnInit() {}
}
