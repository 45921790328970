import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from "firebase/app";
import { Observable } from "rxjs";
import { Tasks, SlackChannels, stage } from "../../../../types/types";
import { environment } from "../../../../types/environments/environment";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  public user: Observable<firebase.User | null>;
  public userDetails: firebase.User;
  constructor(
    public _snackBar: MatSnackBar,
    public http: HttpClient,
    private _firebaseAuth: AngularFireAuth,
    public fs: AngularFirestore
  ) {
    this.user = _firebaseAuth.authState;
    this.user.subscribe(async (user) => {
      if (user) {
        this.userDetails = user;
      }
    });
  }

  snack(message, duration = 7000) {
    this._snackBar.open(message, "👍", {
      duration: duration,
    });
  }

  snackConfirm(message, duration = 15000) {
    return this._snackBar.open(message, "Yes", {
      duration: duration,
    });
  }

  async postToSlack(
    messageText,
    channel: SlackChannels = SlackChannels.general
  ) {
    const message = `${this.userDetails.email} -> ${messageText}`;
    this.createTask(Tasks.slack, { message, channel });
  }

  createTask(type: Tasks, data: { [key: string]: any } = {}) {
    let task = {
      ...data,
      type,
      uid: this.userDetails.uid,
      platform: environment.platform,
    };
    // this is to allow for the tasks created by admin.
    // without this, the tasks get overwritten by the admins UID
    if ("uid" in data) {
      task.uid = data["uid"];
    }

    this.fs
      .collection("tasks")
      .add(task)
      .then((success) => {})
      .catch((error) => {});
  }
}
