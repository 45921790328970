import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { CreditInstructionsComponent } from '../credit-instructions/credit-instructions.component';
import { SubQuestionSelection } from '../../components/sub-question-selection/sub-question-selection.component';
import { QuestionPart, ReviewType } from '../../../../types/types';
import { AuthService } from '../../services/auth/auth.service';
@Component({
  selector: 'app-review-type-selected-topic',
  templateUrl: './review-type-selected-topic.component.html',
  styleUrls: ['./review-type-selected-topic.component.scss'],
})
export class ReviewTypeSelectedTopicComponent {
  public subQuestionSelection: SubQuestionSelection = { selectedParts: [], marks: 0 };
  public ReviewType = ReviewType;
  public reviewType: ReviewType = ReviewType.self;
  public creditsRequiredForExamPresure = 30;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReviewTypeSelectedTopicDiaglogInput,
    private dialogRef: MatDialogRef<ReviewTypeSelectedTopicComponent>,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public authService: AuthService
  ) {}

  close(cancel: boolean) {
    if (cancel) {
      this.reviewType = ReviewType.back;
    }
    const markDiaglogOutput: MarkDiaglogOutput = {
      reviewType: this.reviewType,
      subQuestionSelection: this.subQuestionSelection,
    };
    this.dialogRef.close(markDiaglogOutput);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

  setSubQuestionSelection(subQuestionSelection: SubQuestionSelection) {
    this.subQuestionSelection = subQuestionSelection;
  }
}

export interface ReviewTypeSelectedTopicDiaglogInput {
  subject: string;
  credits: number;
  creditsPerMark: number;
  subjectId: string;
  marks?: number;
  parts?: QuestionPart[];
  acceptingPeerReviews?: boolean;
  tuitionCourseParticipant?: boolean;
  acceptingActuaryReviews?: boolean;
  isAssignmentQuestion?: boolean;
  reviewType?: ReviewType;
  beforeStarting: boolean;
}

export interface MarkDiaglogOutput {
  reviewType?: ReviewType;
  subQuestionSelection: SubQuestionSelection;
}
