import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: 'app-exam-pressure-submit',
  templateUrl: './exam-pressure-submit.component.html',
  styleUrls: ['./exam-pressure-submit.component.scss']
})
export class ExamPressureSubmitComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ExamPressureSubmitComponent>) {
  }
  
  ngOnInit() {
  }

  close(response) {
    this.dialogRef.close(response);
  }

}
