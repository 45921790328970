import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { CreditInstructionsComponent } from "../credit-instructions/credit-instructions.component";
import { SubQuestionSelection } from "../../components/sub-question-selection/sub-question-selection.component";
import { ExamReview, ReviewType, Paper } from "../../../../types/types";
@Component({
  selector: "app-exam-selection",
  templateUrl: "./exam-selection.component.html",
  styleUrls: ["./exam-selection.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ExamSelectionComponent {
  public subQuestionSelection: SubQuestionSelection = {
    selectedParts: [],
    marks: 0,
  };
  public reviewType: ReviewType = ReviewType.self;
  public creditsRequiredForExamPresure = 30;
  public incompleteExams = false;
  public monthsList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ExamSelectionComponent>,
    public dialog: MatDialog
  ) {
    if (data.incompleteAttempts.length > 0) {
      this.incompleteExams = true;
    }
  }

  close(cancel: boolean) {
    this.dialogRef.close(cancel);
  }

  selectPaper(paper: Paper) {
    this.dialogRef.close(paper);
  }

  selectIncompleteExam(examReview: ExamReview) {
    examReview.examAttemptKey = examReview.id;
    this.dialogRef.close(examReview);
  }
}
