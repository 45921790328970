import { Injectable, Type } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { Router } from "@angular/router";
import { NotificationsService } from "../../services/notifications/notifications.service";
import {
  SetCreditsTask,
  Tasks,
  SelectedPackage,
  Package,
  SetPackageTask,
  Packages,
  Invoice,
  User,
  MembershipConfigs,
  Platforms,
} from "../../../../types/types";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { environment } from "../../../../types/environments/environment";
@Injectable({
  providedIn: "root",
})
export class MembershipService {
  public loaded = false;
  public packageValid = true;
  public membershipDaysRemaining = 0;
  public membershipConfigs: MembershipConfigs;
  public membership: SelectedPackage;
  public showPackages = false;
  public hasOutstandingPop: boolean = false;
  private invoices: Invoice[] = [];
  constructor(
    private auth: AuthService,
    private notifications: NotificationsService,
    public dialog: MatDialog,
    public router: Router
  ) {
    this.auth.user.subscribe(async (user) => {
      if (user) {
        await this.getMembershipPricingDetails();
        if (user.email && user.uid) {
          this.loadMembership(user.uid, user.email);
          this.loadInvoices(user.uid);
        }
      }
    });
  }

  loadInvoices(uid: string) {
    const sessionTimestamp = this.auth.getSessionTS();
    this.auth.fs
      .collection("invoices", (ref) =>
        ref
          .where("uid", "==", uid)
          .where("timestamp", ">=", sessionTimestamp)
          .orderBy("timestamp", "desc")
      )
      .snapshotChanges()
      .subscribe((d) => {
        this.invoices = [];
        this.hasOutstandingPop = false;
        d.forEach((invoice) => {
          const invoiceData = invoice.payload.doc.data() as Invoice;
          invoiceData["id"] = invoice.payload.doc.id;
          if (!invoiceData.POP) {
            this.hasOutstandingPop = true;
          }
          this.invoices.push(invoiceData);
        });
      });
  }

  loadMembership(uid: string, email: string) {
    this.getCurrentMembership(uid).subscribe(async (data) => {
      await this.auth.loadUserRights();
      if (data.length === 0) {
        this.purchaseMembership(Packages.trial, uid, email);
      } else if (this.auth.marker) {
        this.packageValid = true;
        this.membershipDaysRemaining = 999;
        this.showPackages = false;
        this.loaded = true;
      } else {
        this.membership = data[0] as SelectedPackage;

        // Calculate days remaining
        this.membershipDaysRemaining = this.calculateDaysLeft(
          this.membership["day_limit"],
          this.membership["timestamp"]
        );

        // Notify user of days remaining if on trial
        if (
          this.membership.membership_descr === Packages.trial &&
          this.membershipDaysRemaining > 0
        ) {
          this.showPackages = true;
          if (this.membershipDaysRemaining === 1) {
            this.notifications.snack("One day remaining on trial");
          } else {
            this.notifications.snack(
              this.membershipDaysRemaining + " days remaining on trial"
            );
          }
        } else {
          // Expired
          if (this.membershipDaysRemaining <= 0) {
            this.packageValid = false;
            this.showPackages = true;
            if (this.auth.marker === false) {
              this.notifications.snack(
                "Your package has expired and needs to be renewed"
              );
              // transfer to subjects page
              this.router.navigate(["/subjects"]);
            }
          }
        }

        // Show packages during development
        if (this.auth.dev) {
          this.showPackages = true;
        }
        this.loaded = true;
      }
    });
  }

  calculateDaysLeft(dayLimit, startTimestamp) {
    const used =
      (new Date().getTime() - startTimestamp) / (1000 * 60 * 60 * 24);
    let days = dayLimit - used;
    days = Math.ceil(days);
    if (!isNaN(days)) {
      return Math.max(0, days);
    } else {
      return 0;
    }
  }

  getMembershipPricingDetails() {
    return new Promise((resolve, reject) => {
      this.auth.fs
        .collection("membership-configs")
        .get()
        .subscribe((data) => {
          this.membershipConfigs = {};
          data.forEach((config) => {
            const c = config.data() as Package;
            this.membershipConfigs[c.membership_descr] = c;
          });
          resolve(true);
        });
    });
  }

  getCurrentMembership(uid) {
    return this.auth.fs
      .collection("memberships", (ref) =>
        ref
          .where("uid", "==", uid)
          .where("verified", "==", true)
          .orderBy("timestamp", "desc")
          .limit(1)
      )
      .valueChanges();
  }

  purchaseMembership(membership_descr: Packages, uid: string, email: string) {
    const data: SetPackageTask = {
      uid,
      type: Tasks.setPackage,
      packageDescription: membership_descr,
      verified: membership_descr === Packages.trial,
      platform: environment.platform,
    };
    this.notifications.createTask(Tasks.setPackage, data);
    if (membership_descr !== Packages.trial) {
      this.notifications.snack(
        "Invoice sent. Your membership will be activated once proof of payment is uploaded."
      );
    }
    this.loaded = true;
  }

  getMembershipConfig(description: Packages): Promise<Package> {
    return new Promise((resolve, reject) => {
      this.auth.fs
        .collection("membership-configs", (ref) =>
          ref.where("membership_descr", "==", description)
        )
        .get()
        .subscribe((membershipConfigs) => {
          membershipConfigs.forEach((membershipConfig) => {
            resolve(membershipConfig.data() as Package);
          });
        });
    });
  }

  async getLatestUserMembership(
    uid,
    active: boolean
  ): Promise<SelectedPackage | null> {
    return new Promise((resolve, reject) => {
      this.auth.fs
        .collection("memberships", (ref) =>
          ref
            .where("uid", "==", uid)
            .where("verified", "==", active)
            .orderBy("timestamp", "asc")
            .limitToLast(1)
        )
        .get()
        .subscribe((memberships) => {
          memberships.forEach((membership) => {
            const membershipData = membership.data();
            membershipData["id"] = membership.id;
            resolve(membershipData as SelectedPackage);
          });
          if (memberships.empty) {
            resolve(null);
          }
        });
    });
  }

  latestUserMembership(uid, active: boolean) {
    return this.auth.fs
      .collection("memberships", (ref) =>
        ref
          .where("uid", "==", uid)
          .where("verified", "==", active)
          .orderBy("timestamp", "asc")
          .limitToLast(1)
      )
      .snapshotChanges();
  }

  async addUserMembership(membership) {
    return new Promise((resolve, reject) => {
      this.auth.fs
        .collection("memberships")
        .add(membership)
        .then((d) => {
          console.log("Membership added");
          resolve(true);
        });
    });
  }

  // Set verified = true
  async verifyMembership(id: string) {
    return new Promise((resolve, reject) => {
      this.auth.fs
        .collection("memberships")
        .doc(id)
        .update({ verified: true })
        .then((d) => {
          console.log("Membership verified");
          resolve(true);
        });
    });
  }

  // Get credits
  async getCredits(uid: string) {
    return new Promise((resolve, reject) => {
      this.auth.fs
        .collection("users")
        .doc(uid)
        .get()
        .subscribe((d) => {
          if (d.exists) {
            if (d.data()) {
              const user = d.data() as User;
              resolve(user.credits);
            } else {
              reject();
            }
          } else {
            reject();
          }
        });
    });
  }

  // Set credits
  async changeCredits(
    credits: number,
    description: string,
    sendEmail: boolean = false,
    uid: string = "NULL"
  ) {
    if (uid === "NULL") {
      uid = this.auth.userInfo.uid;
    }
    const data: SetCreditsTask = {
      uid,
      credits,
      description,
      sendEmail,
      type: Tasks.credits,
      platform: environment.platform,
    };
    this.notifications.createTask(Tasks.credits, data);
  }
}
