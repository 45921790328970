import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

import { AuthService } from "../../services/auth/auth.service";
import { DataServiceService } from "../../services/data/data-service.service";
import { NotificationsService } from "../../services/notifications/notifications.service";

import { Podcast, SlackChannels } from '../../../../types/types'; // Adjust the path to where the Podcast model is defined



@Component({
  selector: 'app-podcast',
  templateUrl: './podcast.component.html',
  styleUrls: ['./podcast.component.scss']
})
export class PodcastComponent {
  data: Podcast[] = [];
  loaded: Boolean = false;

  constructor(
    private dialogRef: MatDialogRef<PodcastComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    public authService: AuthService,
    private dataService: DataServiceService,
    public notificationsService : NotificationsService
  ) {

  }

  ngOnInit(): void {
    this.loadPodcasts();
  }

  async loadPodcasts() {
    const podcasts = await this.dataService.loadPodcasts()

    if (podcasts.length > 0) {
      // sort from latest to most recent podcasts
      this.data = podcasts.sort((a, b) => {
        if (a.season === b.season) {
          return b.episode - a.episode;
        }
        return b.season - a.season;
      });
    }
    this.loaded = true
  }

  openLink(podcastInfo): void {
    window.open(podcastInfo.link, '_blank')
    this.notificationsService.postToSlack(
      ` -> PODCAST OPENED`,
      SlackChannels.podcasts
    );
    // this.dataService.recordPodcastClick(podcastInfo as Podcast)
  }

  closeDialog() {
    this.dialogRef.close(undefined);
  }

}