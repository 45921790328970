import { environment as env } from "./law.dev";
import { stage } from "../types";

export const environment = {
  ...env,
  envName: stage.PROD,
  firebase: {
    apiKey: "AIzaSyCwVZ91-VH7rCDJhgTzcA6_A0ExNLAG2VU",
    authDomain: "thinklawyer.co.za",
    databaseURL: "https://thinkactuarylearn.firebaseio.com",
    projectId: "thinkactuarylearn",
    storageBucket: "thinkactuarylearn.appspot.com",
    messagingSenderId: "909732792696",
    appId: "1:909732792696:web:0161224cfcb17e6649e08d",
    measurementId: "G-4ETCN6GW9W",
  },
};
