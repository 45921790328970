import { Component, OnInit } from "@angular/core";
import { MarkerService } from "../../services/marker/marker.service";
import { AuthService } from "../../services/auth/auth.service";
import { MarkerReport, ExamSessions } from "../../../../types/types";

@Component({
  selector: "app-marker-summary",
  templateUrl: "./marker-summary.component.html",
  styleUrls: ["./marker-summary.component.scss"],
})
export class MarkerSummaryComponent implements OnInit {
  public markerReport: MarkerReport;

  constructor(
    public authService: AuthService,
    public markerService: MarkerService
  ) {
    this.authService.user.subscribe((user) => {
      if (user && user.email) {
        this.loadMarkerStats(user.email);
      }
    });
  }

  ngOnInit() {}

  loadMarkerStats(email: string) {
    const year = new Date().getFullYear();
    const session = this.getSession();
    const identifier = `${email}_${year}_${session}`;

    this.authService.fs
      .collection("marker_reporting")
      .doc(identifier)
      .valueChanges()
      .subscribe((data) => {
        this.markerReport = data as MarkerReport;
      });
  }
  getSession(): number {
    let session = ExamSessions.First;
    if (new Date().getMonth() > 5) {
      session = ExamSessions.Second;
    }
    return session;
  }
}
