import {
  Component,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { AuthService } from "../../services/auth/auth.service";
import { CreditInstructionsComponent } from "../../dialogs/credit-instructions/credit-instructions.component";
import { MarkConfirmComponent } from "../../dialogs/mark-confirm/mark-confirm.component";
import { ReviewTypeSelectedTopicComponent } from "../../dialogs/review-type-selected-topic/review-type-selected-topic.component";
import { ExamPressureEndComponent } from "../../dialogs/exam-pressure-end/exam-pressure-end.component";
import { ExamPressureSubmitComponent } from "../../dialogs/exam-pressure-submit/exam-pressure-submit.component";
import { ExamSelectionComponent } from "../../dialogs/exam-selection/exam-selection.component";
import { AI } from "../../dialogs/ai/ai.component";
import { QuestionsComponent } from "../../dialogs/questions/questions.component";
import { PodcastComponent } from "../../dialogs/podcast/podcast.component";

import * as ClassicEditor from "../../ckeditor5";
import { NotificationsService } from "../../services/notifications/notifications.service";
import { MembershipService } from "../../services/membership/membership.service";
import { DataServiceService } from "../../services/data/data-service.service";
import {
  ReviewType,
  QuestionData,
  SubjectData,
  SlackChannels,
  Review,
  SelectExamOutput,
  ExamReview,
  User,
  Tasks,
  CreateSheetTask,
  Sheet,
  DesktopStates,
  Topic,
  Desktop,
  Paper,
} from "../../../../types/types";
import {
  MarkDiaglogInput,
  MarkDiaglogOutput,
} from "../../dialogs/mark-confirm/mark-confirm.component";
import { SubQuestionSelection } from "../../components/sub-question-selection/sub-question-selection.component";
import { AnalyticsService } from "../../services/analytics/analytics.service";
import { text } from "@angular/core/src/render3";
import { AdvertiserPopupComponent } from "../../dialogs/advertiser/advertiser.component";
import { environment } from "../../../../types/environments/environment";

enum State {
  loading = "loading",
  selectTopic = "selectTopic",
  recommendationLoading = "recommendationLoading",
  doQuestions = "doQuestions",
}

enum Loading {
  subject = "Loading desktop, hang tight ...",
  question = "Loading question ...",
}

interface Continue {
  text: string;
  visible: boolean;
  topicId: string;
  subTopicId: string;
}

@Component({
  selector: "app-desktop",
  templateUrl: "./desktop.component.html",
  styleUrls: ["./desktop.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DesktopComponent {
  @ViewChild("top") subjectsHTML: ElementRef;

  public state: State = State.loading;
  public loadingDescription: Loading = Loading.subject;
  public subjectCode = "";
  public subjectKey: string;
  public subjectData: SubjectData;
  public desktop: Desktop;
  public topics: Topic[] = [];
  public topicsLevel1: Topic[] = [];
  public allTopicsLevel2: Topic[] = [];
  public allTopicsLevel2Dict: { [key: string]: Topic[] } = {};
  public topicsLevel2: Topic[] = [];
  public questionsToDo: QuestionData[];
  public questionData: QuestionData;
  public selectedTopicL1: Topic | undefined;
  public selectedTopicL2: Topic | undefined;
  public continue: Continue = {
    visible: false,
    text: "",
    topicId: "",
    subTopicId: "",
  };

  // Question and Memo section
  public Editor = ClassicEditor;
  public source = null;
  public questionCount = 0;
  public question_html = null;
  public memo_html = null;
  public questionAttempt = 0;
  public secsBeforeFeedback = 60;
  public topicSelectedQuestion = false;
  public busySaving = false;
  public subjectCodeDisplay = "";
  public subjectName = "";
  public examPressureOn = false;
  public examPressureReviewType: ReviewType;
  public selectedQuestionIndex = 0;
  public submittedForMarking = false;
  public subQuestionSelection: SubQuestionSelection;
  public toBePeerReviewed = false;
  public canRequestPeerReview = false;
  public isAssignment: boolean = false;
  public canAddSheet = false;
  public currentQuestionLoadedTimestamp = new Date().getTime();
  public sheetLink;
  public attemptText = "";
  public attempted = false;
  public credits = 0;
  public reviews = [];
  public unfinishedExamAttempts: ExamReview[] = [];
  public selectedQuestionKey: string | undefined;

  // Timer section
  public mark = 0;
  public timeLeft = 0;
  public timeLeft_thres = 0;
  public questionAttempted = false;
  public interval;
  public min_remaining = 0;
  public sec_remaining = 0;
  public time = 0;
  public secondsPerMark = 1.8 * 60;
  public acceptingActuaryReviews = false;
  public acceptingPeerReviews = false;

  // Advertiser
  public showAd = false;

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    private notificationsService: NotificationsService,
    private membershipService: MembershipService,
    private dataService: DataServiceService,
    public analyticsService: AnalyticsService
  ) {
    this.authService.user.subscribe(async (user) => {
      if (user) {
        this.loadSheets(user.uid);
        this.subjectCode = this.getSubjectCode(this.authService.router.url);
        this.setCurrentSubjectCode(this.subjectCode, user.uid);
        const validSubject = await this.authService.validateSubjectKey(
          this.subjectCode
        );
        if (validSubject) {
          this.authService.userInfo = await this.authService.getUserInfoOnce(
            user.uid
          );
          if (!this.authService.userInfo["tcsAccepted"]) {
            this.authService.termsAcceptedCheck();
          }
          this.notificationsService.postToSlack("desktop", SlackChannels.pages);
          this.loadUserCredits();
          this.loadSubjectData();
          this.subjectKey = await this.dataService.getSubjectKeyFromCode(
            this.subjectCode
          );
          await this.dataService.loadSubjectData(this.subjectKey);
          this.loadDesktop();
          this.unfinishedExamAttempts =
            await this.dataService.getIncompleteExamAttempts(this.subjectKey);
        } else {
          this.authService.router.navigate(["/subjects"]);
        }
        if (!membershipService.packageValid) {
          this.authService.router.navigate(["/subjects"]);
        }
      }
    });
  }

  getSubjectCode(url: string): string {
    return url.replace("/desktop/", "");
  }

  loadSubjectData() {
    this.authService.fs
      .collection("subjects", (ref) =>
        ref.where("code", "==", this.subjectCode)
      )
      .get()
      .subscribe((data) => {
        data.forEach(async (d) => {
          this.subjectData = d.data() as SubjectData;
          this.loadAd();
          this.subjectCodeDisplay = this.subjectData["codeDisplay"];
          this.subjectName = this.subjectData["name"];
          this.acceptingActuaryReviews = this.subjectData["reviews"]["actuary"];
          this.acceptingPeerReviews = this.subjectData["reviews"]["peer"];
          this.canRequestPeerReview = await this.authService.peerReviews(
            this.authService.userInfo.uid,
            this.subjectCodeDisplay.toUpperCase()
          );
        });
      });
  }

  loadAd() {
    if (this.subjectData.advertiser) {
      if (this.subjectData.advertiser.active) {
        this.showAd = true;
      }
    }
  }

  advertiserPopup() {
    if (this.subjectData.advertiser) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = "50vw";
      dialogConfig.data = this.subjectData.advertiser;
      this.dialog
        .open(AdvertiserPopupComponent, dialogConfig)
        .afterClosed()
        .subscribe((result) => {
          console.log("The dialog was closed");
        });
      this.notificationsService.postToSlack(
        `${this.subjectData.advertiser.subject} -> ${this.subjectData.advertiser.name} -> POPUP OPENED`,
        SlackChannels.advertisers
      );
    }
  }

  advertiserWebsite() {
    if (this.subjectData.advertiser) {
      const url = new URL(this.subjectData.advertiser.website);
      if (url.hostname !== window.location.hostname) {
        window.open(url.href, "_blank");
      } else {
        console.error("Cannot open a local URL in a new tab");
      }
    }
  }

  setCurrentSubjectCode(currentSubjectCode, userId) {
    this.authService.fs
      .collection("users")
      .doc(userId)
      .update({ currentSubjectCode });
  }

  loadDesktop() {
    const userId = this.authService.userInfo.uid;
    const subjectId = this.subjectKey;
    this.authService.fs
      .collection("desktop")
      .doc(`${userId}_${subjectId}`)
      .snapshotChanges()
      .subscribe((snapshot) => {
        if (snapshot.payload.exists) {
          this.desktop = snapshot.payload.data() as Desktop;
          if (this.desktop.analytics) {
            if (
              !this.topicsLevel1 ||
              JSON.stringify(this.desktop.analytics.topicCoverageLevel1) !==
                JSON.stringify(this.topicsLevel1)
            ) {
              this.topicsLevel1 = this.desktop.analytics
                .topicCoverageLevel1 as Topic[];
            }
            this.allTopicsLevel2 = this.desktop.analytics
              .topicCoverageLevel2 as Topic[];
            this.topicsLevel1.forEach((t) => {
              this.allTopicsLevel2Dict[t.key] = this.allTopicsLevel2.filter(
                // @ts-ignore
                (t2) => t2.key.includes(t.key)
              );
            });
            if (this.state === State.loading) {
              this.state = State.selectTopic;
            }
            if (
              this.desktop.analytics.lastQuestionTimestamp !== null &&
              this.desktop.focusTopicId &&
              this.desktop.focusSubTopicId
            ) {
              const topicText = this.allTopicsLevel2.filter(
                // @ts-ignore
                (t2) => t2.key.includes(this.desktop.focusSubTopicId)
              )[0].topic;
              this.continue.topicId = this.desktop.focusTopicId;
              this.continue.subTopicId = this.desktop.focusSubTopicId;
              this.continue.visible = true;
              this.continue.text = `Continue Topic: ${topicText}`;
            } else {
              this.continue.visible = false;
            }
          }
          if (this.desktop.focusTopicId && this.desktop.focusSubTopicId) {
            this.filterSubtopics();
          }
          if (this.desktop.aiEnabled && this.desktop.questions) {
            this.questionsToDo = [];
            this.desktop.questions.forEach((qd) => {
              const question = this.dataService.allSubjectQuestions.find(
                (q) => q.key === qd.questionId
              );
              if (question) {
                question.recommendationMeta = qd;
                this.questionsToDo.push(question);
              }
            });
          }
        } else {
          console.log("No payload");
          this.notificationsService.createTask(Tasks.refreshAnalytics, {
            userId,
            subjectId,
          });
        }
      });
  }

  doFlashCards() {
    const userId = this.authService.userInfo.uid;
    const subjectId = this.subjectKey;
    this.authService.router.navigate([`/flashcards/${userId}_${subjectId}`]);
  }

  doMultipleChoice() {
    const userId = this.authService.userInfo.uid;
    const subjectId = this.subjectKey;
    this.authService.router.navigate([`/mcq/${userId}_${subjectId}`]);
  }

  continueQuestions() {
    this.setFocusSubTopicId(this.continue.topicId, this.continue.subTopicId);
  }

  loadSheets(uid) {
    this.authService.fs
      .collection("sheets", (ref) =>
        ref.where("uid", "==", uid).orderBy("created", "asc").limitToLast(1)
      )
      .valueChanges()
      .subscribe((data) => {
        data.forEach(async (d) => {
          const sheetData = d as Sheet;
          if (sheetData.created >= this.currentQuestionLoadedTimestamp) {
            if (!this.sheetLink) {
              this.sheetLink = sheetData.link;
              this.openSheet(sheetData.link);
            }
          }
        });
      });
  }

  loadUserCredits() {
    this.authService.fs
      .collection("users")
      .doc(this.authService.userInfo["uid"])
      .valueChanges()
      .subscribe((data) => {
        this.credits = (<User>data).credits;
      });
  }

  setFocusSubTopicId(focusTopicId, focusSubTopicId) {
    const userId = this.authService.userInfo.uid;
    const subjectId = this.subjectKey;
    // @ts-ignore
    this.selectedTopicL1 = this.desktop.analytics.topicCoverageLevel1.find(
      (t) => t.key === focusTopicId
    );
    // @ts-ignore
    this.selectedTopicL2 = this.desktop.analytics.topicCoverageLevel2.find(
      (t) => t.key === focusSubTopicId
    );
    this.openExamPressureConfirmationDialog();
    if (
      this.desktop.focusSubTopicId !== focusSubTopicId ||
      // @ts-ignore
      this.desktop.questions.length === 0
    ) {
      this.authService.fs
        .collection("desktop")
        .doc(`${userId}_${subjectId}`)
        .update({
          focusTopicId,
          focusSubTopicId,
          questions: [],
          multipleChoiceQuestions: [],
          flashCards: [],
          status: "Waiting ...",
          mcqStatus: "Waiting ...",
          flashCardStatus: "Waiting ...",
          state: "refreshing",
        })
        .then((d) => {
          this.notificationsService.createTask(Tasks.generateAiRecommendation, {
            userId,
            subjectId,
          });
        });
    }
  }

  filterSubtopics() {
    if (this.desktop.focusTopicId) {
      this.topicsLevel2 = this.allTopicsLevel2.filter(
        // @ts-ignore
        (t) => t.level == 2 && t.key.includes(this.desktop.focusTopicId)
      );
    }
  }

  async loadQuestion(questionData: QuestionData): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (this.membershipService.packageValid) {
        try {
          this.canAddSheet = true;
          this.currentQuestionLoadedTimestamp = new Date().getTime();
          this.questionData = questionData;
          this.selectedQuestionKey = this.questionData.key;
          this.isAssignment = this.checkIfAssignmentQuestion();
          this.scroll();
          this.time = 0;
          this.submittedForMarking = false;
          this.timeLeft = this.questionData["marks"] * this.secondsPerMark;
          this.startTimer();
          this.loadAttemptText();
          this.state = State.doQuestions;
          resolve();
        } catch (error) {
          reject(error);
        }
      } else {
        this.authService.router.navigate(["/account"]);
        this.notificationsService.snack("Subscription expired");
      }
    });
  }

  startTimer() {
    let update_question = 0;
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      if (this.timeLeft >= 0) {
        this.timeLeft--;
        this.time++;
        this.min_remaining = Math.floor(this.timeLeft / 60);
        this.sec_remaining = Math.floor(
          this.timeLeft - this.min_remaining * 60
        );
        if (this.timeLeft <= this.timeLeft_thres && update_question === 0) {
          update_question = 1;
        }
        if (this.timeLeft <= 0 && this.examPressureOn) {
          this.openExamPressureEndDialog();
        }
      } else {
        this.min_remaining = 0;
        this.sec_remaining = 0;
      }
    }, 1000);
  }

  saveAttemptText(userInitiated: boolean = false) {
    this.busySaving = true;
    if (userInitiated) {
      this.notificationsService.postToSlack(
        "Question saved",
        SlackChannels.analytics
      );
    }
    if (this.attemptText === "") {
      this.notificationsService.snack("Nothing to save", 1000);
    } else {
      this.authService.saveAttemptText(this.questionData.key, this.attemptText);
      this.notificationsService.snack("Attempt saved!", 1000);
    }
  }

  async loadAttemptText() {
    const attempt = await this.authService.loadAttemptText(
      this.questionData.key
    );
    this.attempted = false;
    if (attempt) {
      if (!this.examPressureOn) {
        this.attemptText = attempt.attemptText;
        this.attempted = true;
      }
    } else {
      this.attemptText = "";
    }
  }

  async doQuestions() {
    this.selectedQuestionKey = undefined;
    if (this.desktop.aiEnabled && this.authService.aiConfig.recommendations) {
      this.state = State.recommendationLoading;
    } else {
      this.loadingDescription = Loading.question;
      this.state = State.loading;
      this.questionsToDo = [...this.dataService.allSubjectQuestions];
      if (this.desktop.focusSubTopicId !== undefined) {
        this.questionsToDo = this.questionsToDo.filter(
          // @ts-ignore: Object is possibly 'undefined'.
          (q) => q.topics.includes(this.desktop.focusSubTopicId)
        );
      }
      this.nextQuestion();
    }
  }

  async nextQuestion() {
    this.loadingDescription = Loading.question;
    this.state = State.loading;
    let index = 0;
    if (this.selectedQuestionKey) {
      index =
        this.questionsToDo.findIndex(
          (q) => q.key === this.selectedQuestionKey
        ) + 1;
      if (
        !index ||
        index >= this.questionsToDo.length ||
        !this.questionsToDo[index]
      )
        index = 0;
    }
    await this.loadQuestion(this.questionsToDo[index]);
  }

  removeAttemptedQuestion(questionId: string) {
    if (this.desktop.questions) {
      this.desktop.questions = this.desktop.questions.filter(
        (question) => question.questionId !== questionId
      );
      const userId = this.authService.userInfo.uid;
      const subjectId = this.subjectKey;
      this.authService.fs
        .collection("desktop")
        .doc(`${userId}_${subjectId}`)
        .update({ questions: this.desktop.questions });
    }
  }

  createAttemptObject(
    reviewType: ReviewType,
    subQuestionSelection?: SubQuestionSelection
  ): Review {
    const marks = subQuestionSelection
      ? subQuestionSelection.marks
      : this.questionData.marks;
    const partsToBeMarked = subQuestionSelection
      ? subQuestionSelection.selectedParts
      : this.questionData.parts;
    const partsToBeMarkedCleaned = partsToBeMarked.map((part) => {
      let newPart = part;
      delete newPart.question_html;
      delete newPart.memo_html;
      return newPart;
    });
    let creditsUsed = 0;
    if (reviewType === ReviewType.actuary) {
      creditsUsed = this.subjectData.creditsPerMark * marks;
    }
    return {
      user: this.authService.userInfo["uid"],
      email: this.authService.userInfo["email"],
      subject: this.subjectCode,
      subjectId: this.subjectKey,
      subjectName: this.subjectName,
      mark: marks,
      creditsUsed,
      source: this.questionData.source,
      partsToBeMarked: partsToBeMarkedCleaned,
      question_identifier: this.questionData.question_string,
      questionKey: this.questionData.key,
      questionNumber: this.questionData.number,
      attemptText: this.attemptText,
      reviewed: false,
      state: 0,
      timestamp_submit: Date.now(),
      time_submitted: new Date().getTime(),
      reviewed_by: false,
      reviewer_comments_html: "",
      email_underway: false,
      email_completed: false,
      score: 0,
      score_mark: 0,
      reviewType,
      feedback_quality: "0",
      questionTrailer: this.dataService.getQuestionPreview(partsToBeMarked, 75),
      structure_score: {
        key_ideas: 0,
        info_used: 0,
        concise: 0,
        idea_generated: 0,
      },
      ...(this.sheetLink && { sheetLink: this.sheetLink }),
    };
  }

  saveAttemptForMarking(attemptObject) {
    return new Promise((resolve, reject) => {
      this.authService.fs
        .collection("reviews")
        .add(attemptObject)
        .then((d) => {
          resolve(d.id);
        })
        .catch((e) => {
          reject(true);
        });
    });
  }

  async markAttempt(
    reviewType: ReviewType,
    subQuestionSelection?: SubQuestionSelection
  ) {
    if (this.attemptText === "") {
      this.notificationsService.snack("Answer sheet empty, not proceeding");
    } else if (!this.authService.internetConnected) {
      this.notificationsService.snack("No internet connection, not proceeding");
    } else {
      const attemptObject = this.createAttemptObject(
        reviewType,
        subQuestionSelection
      );
      this.saveAttemptText();
      this.submittedForMarking = true;
      const attemptKey = await this.saveAttemptForMarking(attemptObject);
      this.removeAttemptedQuestion(attemptObject.questionKey);
      if (reviewType === ReviewType.actuary) {
        this.membershipService.changeCredits(
          -attemptObject.creditsUsed,
          "Marking"
        );
        this.notificationsService.postToSlack(
          "Question submitted for actuary review",
          SlackChannels.reviews
        );
        this.notificationsService.snack(
          "Your attempt has been sent to an actuary for review. It should be marked within 48 hours. You will receive an email once the review is complete."
        );
      }
      if (reviewType === ReviewType.assignment) {
        this.notificationsService.snack(
          "Your attempt has been sent to your tuition course leader for review. You will receive an email once the review is complete."
        );
        this.notificationsService.postToSlack(
          "Question submitted for course leader review",
          SlackChannels.reviews
        );
      }
      if (reviewType === ReviewType.peer) {
        this.notificationsService.snack(
          "Your attempt has been sent to one of your tuition course peers for review. It should be marked within 48 hours. You will receive an email once the review is complete."
        );
        this.notificationsService.postToSlack(
          "Question submitted for peer review",
          SlackChannels.reviews
        );
      }
      if (reviewType === ReviewType.self) {
        this.notificationsService.postToSlack(
          "Self-mark question completed",
          SlackChannels.reviews
        );
        this.authService.router.navigate(["/mark/" + attemptKey]);
      }
      this.state = State.doQuestions;
    }
  }

  resetText() {
    this.notificationsService
      .snackConfirm("Clear attempt?", 2000)
      .onAction()
      .subscribe(() => {
        this.attemptText = "";
      });
  }

  imDone() {
    if (this.attemptText === "") {
      return this.notificationsService.snack("No answer text");
    }
    if (!this.authService.internetConnected) {
      return this.notificationsService.snack("Check your internet connection");
    }
    if (!this.examPressureOn) {
      this.openMarkingTypeDialog();
    } else {
      this.openSubmitConfirmDialog();
    }
  }

  checkIfAssignmentQuestion() {
    if (this.questionData.assignment) {
      if (this.questionData.assignment.dueDate >= new Date().getTime()) {
        return true;
      }
    }
    return false;
  }

  async openExamSelectionDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "50%";
    const papers = this.dataService.papers.sort(
      this.dataService.fieldSorterReverse(["year", "session", "number"])
    );
    dialogConfig.data = {
      papers,
      incompleteAttempts: this.unfinishedExamAttempts,
    };
    this.dialog
      .open(ExamSelectionComponent, dialogConfig)
      .afterClosed()
      .subscribe(async (data) => {
        if (data) {
          const selectExamResponse = data as Paper;
          if ("examAttemptKey" in selectExamResponse) {
            this.authService.router.navigate([
              "/exam/" + selectExamResponse["examAttemptKey"],
            ]);
          } else {
            this.dataService.createExamAttempt(selectExamResponse);
          }
        }
      });
  }

  openExamPressureConfirmationDialog() {
    this.examPressureOn = false;
    this.examPressureReviewType = ReviewType.self;
    this.doQuestions();
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.maxWidth = "800px";
    // const data: MarkDiaglogInput = {
    //   subject: this.subjectName,
    //   subjectId: this.subjectKey,
    //   credits: this.credits,
    //   creditsPerMark: this.subjectData.creditsPerMark,
    //   acceptingActuaryReviews: this.acceptingActuaryReviews,
    //   acceptingPeerReviews: this.acceptingPeerReviews,
    //   tuitionCourseParticipant: this.authService.tuitionCourseParticipant,
    //   beforeStarting: true,
    //   reviewType: ReviewType.pending,
    // };
    // dialogConfig.data = data;
    // this.dialog
    //   .open(MarkConfirmComponent, dialogConfig)
    //   .afterClosed()
    //   .subscribe((data) => {
    //     const response = data as MarkDiaglogOutput;
    //     const reviewType = response.reviewType;
    //     if (reviewType !== ReviewType.back) {
    //       this.examPressureOn =
    //         reviewType === ReviewType.actuary || reviewType === ReviewType.peer;
    //       this.examPressureReviewType = reviewType;
    //       this.doQuestions();
    //     } else {
    //       this.examPressureOn = false;
    //     }
    //   });
  }

  openMarkingTypeDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "800px";
    const data: MarkDiaglogInput = {
      subject: this.subjectName,
      subjectId: this.subjectKey,
      creditsPerMark: this.subjectData.creditsPerMark,
      credits: this.credits,
      acceptingActuaryReviews: this.acceptingActuaryReviews,
      acceptingPeerReviews: this.acceptingPeerReviews,
      tuitionCourseParticipant: this.authService.tuitionCourseParticipant,
      beforeStarting: false,
      parts: this.questionData.parts,
      reviewType: ReviewType.pending,
      isAssignmentQuestion: this.checkIfAssignmentQuestion(),
    };
    dialogConfig.data = data;
    this.dialog
      .open(ReviewTypeSelectedTopicComponent, dialogConfig)
      .afterClosed()
      .subscribe((data) => {
        data as MarkDiaglogOutput;
        if (data.reviewType !== ReviewType.back) {
          this.markAttempt(data.reviewType, data.subQuestionSelection);
        }
      });
  }

  openSubmitConfirmDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { subject: this.subjectName };
    this.dialog
      .open(ExamPressureSubmitComponent, dialogConfig)
      .afterClosed()
      .subscribe((proceed) => {
        if (proceed) {
          this.timeLeft = 0;
        }
      });
  }

  openExamPressureEndDialog() {
    if (
      this.examPressureReviewType === ReviewType.actuary ||
      this.examPressureReviewType === ReviewType.peer
    ) {
      const data: MarkDiaglogInput = {
        subject: this.subjectName,
        subjectId: this.subjectKey,
        creditsPerMark: this.subjectData.creditsPerMark,
        credits: this.credits,
        marks: this.questionData.marks,
        parts: this.questionData.parts,
        reviewType: this.examPressureReviewType,
        beforeStarting: false,
      };
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = data;
      this.dialog
        .open(ExamPressureEndComponent, dialogConfig)
        .afterClosed()
        .subscribe((data) => {
          const markConfirmResponse = data as MarkDiaglogOutput;
          this.markAttempt(
            this.examPressureReviewType,
            markConfirmResponse.subQuestionSelection
          );
        });
    } else {
      this.markAttempt(this.examPressureReviewType);
    }
    this.examPressureOn = false;
  }

  openActivateAliceDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.desktop;
    dialogConfig.maxWidth = "50vw";
    this.dialog.open(AI, dialogConfig);
  }

  openQuestionsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "80%";
    dialogConfig.data = {};
    this.dialog
      .open(QuestionsComponent, dialogConfig)
      .afterClosed()
      .subscribe((data) => {
        const response = data as string | undefined;
        if (response) {
          this.state = State.loading;
          this.loadQuestionUsingKey(response);
        }
      });
  }

  async openPodcastsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.maxHeight = "80%";
    dialogConfig.data = {};
    this.dialog.open(PodcastComponent, dialogConfig).afterClosed();
  }

  scroll() {
    try {
      this.subjectsHTML.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } catch (e) {
      console.log(e);
    }
  }

  backToMenu() {
    this.notificationsService
      .snackConfirm("Go back?", 7500)
      .onAction()
      .subscribe(() => {
        this.min_remaining = 0;
        this.sec_remaining = 0;
        this.state = State.selectTopic;
        this.scroll();
      });
  }

  addSheet() {
    const newSheet: CreateSheetTask = {
      uid: this.authService.userDetails.uid,
      sheetTitle: `${this.subjectCodeDisplay} ${this.questionData.question_string} `,
      type: Tasks.create_sheet,
      platform: environment.platform,
    };
    this.notificationsService.createTask(Tasks.create_sheet, newSheet);
    this.canAddSheet = false;
  }

  openSheet(sheetLink: string) {
    window.open(sheetLink, "_blank");
  }

  openReview(reviewKey) {
    this.authService.router.navigate([`mark/${reviewKey}`]);
  }

  async loadQuestionUsingKey(questionKey) {
    this.state = State.loading;
    const question = this.dataService.allSubjectQuestions.find(
      (q) => q.key === questionKey
    );
    if (question) {
      this.questionsToDo = [question];
      await this.loadQuestion(question);
    } else {
      this.state = State.selectTopic;
    }
  }

  updateFeedbackQuality(review) {
    if (review.feedback_quality * 1 <= 3) {
      let msg =
        review.feedback_quality +
        " given for review " +
        review.key +
        " - marked by " +
        review.reviewed_by_email;
      this.notificationsService.postToSlack(
        msg,
        SlackChannels.markerFeedbackIssues
      );
    }
    this.authService.fs
      .collection("reviews")
      .doc(review["key"])
      .update({ feedback_quality: review.feedback_quality })
      .then((d) => {
        console.log("rating captured");
      });
  }

  completeReview(reviewKey) {
    this.authService.router.navigate(["/mark/" + reviewKey]);
  }
}
