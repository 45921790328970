import { Component, OnInit } from '@angular/core';
import { MembershipService } from '../../services/membership/membership.service';
import { AuthService } from '../../services/auth/auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MembershipInstructionsComponent } from '../../dialogs/membership-instructions/membership-instructions.component';
import { Packages, ProfessionalBodies } from '../../../../types/types';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss'],
})
export class PackagesComponent implements OnInit {
  constructor(
    public membershipService: MembershipService,
    public dialog: MatDialog,
    public authService: AuthService
  ) {}

  ngOnInit() {}

  openMembershipInstructionsDialog(membershipDescription: Packages) {
    if (this.authService.professionalBody === ProfessionalBodies.IFOA) {
      const url =
        this.membershipService.membershipConfigs[membershipDescription].prices[
          this.authService.professionalBody
        ].productUrl;
      window.open(url, '_self');
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = { membershipDescription };
      this.dialog.open(MembershipInstructionsComponent, dialogConfig).afterClosed().subscribe();
    }
  }
}
