import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { QuestionPart } from '../../../../types/types';

@Component({
  selector: 'app-sub-question-selection',
  templateUrl: './sub-question-selection.component.html',
  styleUrls: ['./sub-question-selection.component.scss'],
})
export class SubQuestionCollectionComponent implements OnInit {
  @Input() parts: QuestionPart[];
  @Output() subQuestionSelection: EventEmitter<SubQuestionSelection> =
    new EventEmitter<SubQuestionSelection>();
  public selectedParts: QuestionPart[];
  constructor() {}
  select(selectedParts: QuestionPart[]) {
    let marks: number = 0;
    selectedParts.forEach(part => {
      marks += part.marks;
    });
    this.subQuestionSelection.emit({
      selectedParts,
      marks,
    });
  }
  ngOnInit() {}
}

export interface SubQuestionSelection {
  selectedParts: QuestionPart[];
  marks: number;
}
