import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { MarkerService } from "../../services/marker/marker.service";
import { environment } from "../../../../types/environments/environment";
import { MembershipService } from "../../services/membership/membership.service";
import { ExamSessions, MarkerReport } from "../../../../types/types";

@Component({
  selector: "app-marker",
  templateUrl: "./marker.component.html",
  styleUrls: ["./marker.component.scss"],
})
export class MarkerComponent implements OnInit {
  public appName = environment.appName;
  constructor(
    public authService: AuthService,
    public markerService: MarkerService,
    public membershipService: MembershipService
  ) {}

  ngOnInit() {}
}
