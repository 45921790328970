import { Component, OnInit } from "@angular/core";
import {
  User,
  Tasks,
  Package,
  SelectedPackage,
  Packages,
  SetPackageTask,
  InvoiceType,
  SubjectMarkerMapping,
  SubjectData,
} from "../../../../../types/types";
import { AuthService } from "../../../services/auth/auth.service";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { MembershipService } from "../../../services/membership/membership.service";
import { AdminService } from "../../../services/admin/admin.service";
import { environment } from "../../../../../types/environments/environment";
@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit {
  loaded = false;
  user: User;
  uid: string;
  subjectsList: SubjectData[] = [];
  creditsAdd = 0;
  prospectiveMembership: Package | null;
  prospectiveMembershipDescription: Packages;
  currentMembership: Package | null;
  memberships: string[] = [];
  subjectId: string;
  markerSubjects: string[];
  constructor(
    public authService: AuthService,
    public notificationsService: NotificationsService,
    public memberhipService: MembershipService,
    public admin: AdminService
  ) {
    this.memberships = [];
    for (const key in Packages) {
      if (Packages[key]) {
        this.memberships.push(Packages[key]);
      }
    }
    this.uid = this.getUid(this.authService.router.url);
    this.authService.getUserInfo(this.uid).subscribe(async (userData) => {
      await this.loadSubjects();
      this.user = userData as User;
      this.currentMembership =
        await this.memberhipService.getLatestUserMembership(this.uid, true);
      await this.getUserData(this.uid);
      this.memberhipService
        .latestUserMembership(this.uid, true)
        .subscribe((memberships) => {
          memberships.forEach((membership) => {
            const membershipData = membership.payload.doc.data() as any;
            membershipData["id"] = membership.payload.doc.id;
            this.currentMembership = membershipData as SelectedPackage;
          });
        });
      this.loaded = true;
    });
  }

  ngOnInit() {}

  getUid(url: string): string {
    return url.replace("/admin/users/", "");
  }

  loadSubjects() {
    return new Promise(async (resolve, reject) => {
      this.authService.fs
        .collection("subjects")
        .get()
        .subscribe((subjects) => {
          subjects.forEach((subject) => {
            this.subjectsList.push(subject.data() as SubjectData);
          });
          resolve(true);
        });
    });
  }

  async getUserData(uid): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.prospectiveMembership =
        await this.memberhipService.getLatestUserMembership(uid, false);
      if (this.prospectiveMembership) {
        this.prospectiveMembershipDescription =
          this.prospectiveMembership.membership_descr;
      }
      if (this.currentMembership) {
        this.user["daysLeft"] = this.memberhipService.calculateDaysLeft(
          this.currentMembership["day_limit"],
          this.currentMembership["timestamp"]
        );
      }
      this.user["timestamp"] = await this.getUserLastSeen(uid);
      await this.getMarkerSubjects(uid);
      resolve();
    });
  }

  async getUserLastSeen(uid): Promise<number> {
    return new Promise(async (resolve, reject) => {
      this.authService.fs
        .collection("signIns", (ref) =>
          ref.where("uid", "==", uid).orderBy("timestamp", "asc").limitToLast(1)
        )
        .get()
        .subscribe((d) => {
          if (d.empty) {
            resolve(0);
          } else {
            d.forEach((signIn) => {
              resolve(signIn.data()["timestamp"]);
            });
          }
        });
    });
  }

  async setMembership() {
    const prospectiveMembership =
      await this.memberhipService.getLatestUserMembership(this.uid, false);
    if (prospectiveMembership) {
      const prospectiveMembershipDescription =
        prospectiveMembership.membership_descr;
      const data: SetPackageTask = {
        uid: this.uid,
        type: Tasks.setPackage,
        packageDescription: prospectiveMembershipDescription,
        verified: true,
        platform: environment.platform,
      };
      console.log(data);
      this.notificationsService.createTask(Tasks.setPackage, data);
    }
  }

  async addCredits() {
    await this.memberhipService.changeCredits(
      this.creditsAdd,
      "Purchase",
      true,
      this.uid
    );
    this.creditsAdd = 0;
  }

  async updateProspectiveMembership() {
    if (this.authService.professionalBody) {
      const membershipConfig = await this.memberhipService.getMembershipConfig(
        this.prospectiveMembershipDescription
      );
      const membership: SelectedPackage = {
        ...membershipConfig,
        user_email: this.user.email,
        uid: this.uid,
        timestamp: new Date().getTime(),
        verified: false,
        type: InvoiceType.membership,
        timeCreated: new Date().getTime(),
        professionalBody: this.authService.professionalBody,
        cost: membershipConfig.prices[this.authService.professionalBody].units,
        currency:
          membershipConfig.prices[this.authService.professionalBody].currency,
      };
      delete membership["subject"];
      await this.memberhipService.addUserMembership(membership);
      this.prospectiveMembership = membership;
      this.notificationsService.snack(
        "Prospective membership selected, but needs to be activated"
      );
    }
  }

  async makeMarker(uid: string, subjectId: string) {
    if (subjectId) {
      const isMarkerAlready = await this.checkIfMarker(uid, subjectId);
      if (!isMarkerAlready) {
        this.authService.fs
          .collection("markers")
          .add({
            uid: uid,
            active: true,
            subjectId: subjectId,
            lastAllocatedAt: 0,
          })
          .then((d) => {
            this.notificationsService.snack("Marker added");
          });
      } else {
        this.notificationsService.snack(
          "User is a marker for the subject already"
        );
      }
    }
  }

  checkIfMarker(uid: string, subjectId: string) {
    return new Promise(async (resolve, reject) => {
      this.authService.fs
        .collection("markers", (ref) =>
          ref.where("uid", "==", uid).where("subjectId", "==", subjectId)
        )
        .get()
        .subscribe((subjects) => {
          resolve(!subjects.empty);
        });
    });
  }

  async getMarkerSubjects(uid: string) {
    return new Promise<void>((resolve, reject) => {
      this.authService.fs
        .collection("markers", (ref) =>
          ref.where("uid", "==", uid).where("active", "==", true)
        )
        .get()
        .subscribe((subjectList) => {
          this.markerSubjects = [];
          if (subjectList.docs.length > 0) {
            subjectList.forEach(async (subject) => {
              if (!subjectList.empty) {
                const subjectData = subject.data() as SubjectMarkerMapping;
                const name = await this.authService.getSubjectNameFromKey(
                  subjectData.subjectId
                );
                this.markerSubjects.push(name);
              }
            });
          }
          resolve();
        });
    });
  }
}
