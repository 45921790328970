import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../services/auth/auth.service";
import { DataServiceService } from "../../../services/data/data-service.service";
import { PapersService } from "../../../services/papers/papers.service";
import { Paper } from "../../../../../types/types";

@Component({
  selector: "app-paper-management",
  templateUrl: "./paper-management.component.html",
  styleUrls: ["./paper-management.component.scss"],
})
export class PaperManagementComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public dataService: DataServiceService,
    public paperService: PapersService
  ) {}

  ngOnInit() {}

  openPaper(paper: Paper) {
    this.authService.router.navigate([`/papers/${paper.id}`]);
  }

  toggleReleaseStatus(paper: Paper) {
    this.paperService.setPaperReleaseStatus(paper);
  }
}
