import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { environment } from "../../../../types/environments/environment";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit {
  public lottieConfig: Object;

  focus: any;
  focus1: any;
  subjects = environment.subjects;
  appName = environment.appName;
  community = environment.community;
  email = environment.email;
  testimonials = environment.testimonials;
  @ViewChild("method") methodHTML: ElementRef;

  constructor(public authService: AuthService) {
    this.lottieConfig = {
      path: "assets/img/landing/working-chart.json",
      renderer: "canvas",
      autoplay: true,
      loop: true,
    };
  }

  ngOnInit() {}

  SignIn() {
    this.authService.signInWithGoogle().then((res) => {});
  }

  SignOut() {
    this.authService.logout();
  }

  goToLogin() {
    this.authService.router.navigate(["/login"]);
  }

  goToSubjects() {
    this.authService.router.navigate(["/subjects"]);
  }

  scroll() {
    try {
      this.methodHTML.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } catch (e) {
      console.log(e);
    }
  }
}
