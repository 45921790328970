import { Component } from '@angular/core';
import { AdminService } from '../../../services/admin/admin.service';
import { MembershipService } from '../../../services/membership/membership.service';
import { AuthService } from '../../../services/auth/auth.service';
import { Packages, SubjectData } from '../../../../../types/types';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {
  public searchEmail: string;
  public loaded = false;
  public max_users = 200;
  public users: any[] = [];
  public memberships: string[] = [];
  public subjectsList: SubjectData[] = [];
  constructor(
    private admin: AdminService,
    private memberhipService: MembershipService,
    private authService: AuthService
  ) {
    this.memberships = [];
    for (const key in Packages) {
      if (Packages[key]) {
        this.memberships.push(Packages[key]);
      }
    }

    this.loadSubjects();
    this.admin.data.afs
     .collection('users', ref => ref.orderBy('createDate', 'desc').limit(this.max_users))
      .get()
      .subscribe(async users => {
        const promises = users.docs.map(async user => {
          return await this.getUserData(user);
        });
        this.users = await Promise.all(promises);
        this.users.sort(function (a, b) {
          return b['timestamp'] - a['timestamp'];
        });
        this.loaded = true;
      });
  }

  loadSubjects() {
    return new Promise(async (resolve, reject) => {
      this.authService.fs
        .collection('subjects')
        .get()
        .subscribe(subjects => {
          subjects.forEach(subject => {
            this.subjectsList.push(subject.data() as SubjectData);
          });
          resolve(true);
        });
    });
  }

  async getUserData(user) {
    return new Promise(async (resolve, reject) => {
      const u = user.data();
      const currentMembership = await this.memberhipService.getLatestUserMembership(u['uid'], true);
      const prospectiveMembership = await this.memberhipService.getLatestUserMembership(
        u['uid'],
        false
      );
      if (prospectiveMembership) {
        u['prospectiveMembership'] = prospectiveMembership;
      }
      if (currentMembership) {
        u['currentMembership'] = currentMembership;
        u['daysLeft'] = this.memberhipService.calculateDaysLeft(
          currentMembership['day_limit'],
          currentMembership['timestamp']
        );
      }
      u['timestamp'] = await this.getUserLastSeen(u['uid']);
      resolve(u);
    });
  }

  async getUserLastSeen(uid) {
    return new Promise(async (resolve, reject) => {
      this.authService.fs
        .collection('signIns', ref =>
          ref.where('uid', '==', uid).orderBy('timestamp', 'asc').limitToLast(1)
        )
        .get()
        .subscribe(d => {
          if (d.empty) {
            resolve(0);
          } else {
            d.forEach(signIn => {
              resolve(signIn.data()['timestamp']);
            });
          }
        });
    });
  }

  checkIfMarker(uid: string, subjectId: string) {
    return new Promise(async (resolve, reject) => {
      this.authService.fs
        .collection('markers', ref =>
          ref.where('uid', '==', uid).where('subjectId', '==', subjectId)
        )
        .get()
        .subscribe(subjects => {
          resolve(!subjects.empty);
        });
    });
  }

  openUser(uid: string) {
    this.admin.router.navigate(['/admin/users/' + uid]);
  }

  loadAllUsers() {
    this.max_users = 5000
    this.loaded = false
    this.admin.data.afs
     .collection('users', ref => ref.orderBy('createDate', 'desc').limit(this.max_users))
      .get()
      .subscribe(async users => {
        const promises = users.docs.map(async user => {
          return await this.getUserData(user);
        });
        this.users = await Promise.all(promises);
        this.users.sort(function (a, b) {
          return b['timestamp'] - a['timestamp'];
        });
        this.loaded = true;
      });
  }

  searchByEmail() {
    if (!this.searchEmail || this.searchEmail=='') {
      this.admin.data.afs
     .collection('users', ref => ref.orderBy('createDate', 'desc').limit(this.max_users))
      .get()
      .subscribe(async users => {
        const promises = users.docs.map(async user => {
          return await this.getUserData(user);
        });
        this.users = await Promise.all(promises);
        this.users.sort(function (a, b) {
          return b['timestamp'] - a['timestamp'];
        });
        this.loaded = true;
      });
    }
    this.loaded = false; // Indicate that loading has started
    this.admin.data.afs
      .collection('users', ref => ref.where('email', '==', this.searchEmail))
      .get()
      .subscribe(users => {
        const userPromises = users.docs.map(doc => this.getUserData(doc));
        Promise.all(userPromises).then(userDetails => {
          this.users = userDetails;
          this.loaded = true; // Indicate that loading has finished
        });
      });
  }
}
