import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Inject } from "@angular/core";
import { MembershipService } from "../../services/membership/membership.service";
import { AuthService } from "../../services/auth/auth.service";
import { Packages } from "../../../../types/types";
import { environment } from "../../../../types/environments/environment";

interface Input {
  membershipDescription: Packages;
}

@Component({
  selector: "app-membership-instructions",
  templateUrl: "./membership-instructions.component.html",
  styleUrls: ["./membership-instructions.component.scss"],
})
export class MembershipInstructionsComponent implements OnInit {
  public Packages = Packages;
  public bankName = environment.bankName;
  public bankAccountNumber = environment.bankAccountNumber;
  constructor(
    private dialogRef: MatDialogRef<MembershipInstructionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Input,
    public membershipService: MembershipService,
    public authService: AuthService
  ) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }

  purchaseBundle() {
    const selectedPackage: Packages =
      this.membershipService.membershipConfigs[this.data.membershipDescription]
        .membership_descr;
    this.membershipService.purchaseMembership(
      selectedPackage,
      this.authService.userInfo.uid,
      this.authService.userInfo.email
    );
    this.close();
  }

  cancel() {
    this.close();
  }
}
