import { Component } from '@angular/core';
import { MarkerService } from '../../../services/marker/marker.service';
@Component({
  selector: 'app-markers',
  templateUrl: './markers.component.html',
  styleUrls: ['./markers.component.scss'],
})
export class MarkersComponent {
  constructor(public markerService: MarkerService) {}
}
