import {
  Component,
  OnInit,
  Inject,
  Renderer,
  ElementRef,
  ViewChild,
} from "@angular/core";
import "rxjs/add/operator/filter";
import { LocationStrategy, PlatformLocation, Location } from "@angular/common";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { environment as env } from "../../types/environments/environment";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @ViewChild(NavbarComponent) navbar: NavbarComponent;

  constructor(
    private renderer: Renderer,
    private element: ElementRef,
    public location: Location,
    @Inject(DOCUMENT) private document: any
  ) {}
  setFavicon(faviconUrl: string) {
    const linkElement =
      this.document.querySelector(`link[rel*='icon']`) ||
      this.document.createElement("link");
    linkElement.type = "image/x-icon";
    linkElement.rel = "shortcut icon";
    linkElement.href = faviconUrl;

    // Add or change the link element.
    const headElement = this.document.querySelector("head");
    const existingLinkElement =
      this.document.querySelector(`link[rel*='icon']`);
    if (existingLinkElement) {
      headElement.removeChild(existingLinkElement);
    }
    headElement.appendChild(linkElement);
  }
  ngOnInit() {
    this.setFavicon(env.favicon);
    var navbar: HTMLElement =
      this.element.nativeElement.children[0].children[0];
    this.renderer.listenGlobal("window", "scroll", (event) => {
      const number = window.scrollY;
      try {
        if (number > 0 || window.pageYOffset > 0) {
          navbar.classList.remove("navbar-transparent");
        } else {
          navbar.classList.add("navbar-transparent");
        }
      } catch (error) {}
    });
  }
  removeFooter() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.slice(1);
    if (titlee === "signup" || titlee === "nucleoicons") {
      return false;
    } else {
      return true;
    }
  }
}
