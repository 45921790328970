import { Component, Input, OnInit } from "@angular/core";
import {
  QuestionData,
  ProfessionalBodies,
  ProfessionalBodyDescriptions,
  SlackChannels,
} from "../../../../types/types";
import { DataServiceService } from "../../services/data/data-service.service";
import { AuthService } from "../../services/auth/auth.service";
import { AdminService } from "../../services/admin/admin.service";
import { NotificationsService } from "../../services/notifications/notifications.service";
import * as ClassicEditor from "../../ckeditor5";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-render-question",
  templateUrl: "./render-question.component.html",
  styleUrls: ["./render-question.component.scss"],
})
export class RenderQuestionComponent implements OnInit {
  /** Either questionId or questionHTML can be provided to the Component.
   *
   * renderQuestion:
   * If renderQuestion = true then the question is rendered.
   * If renderQuestion = false then the memo is rendered.
   *
   * questionId:
   * If questionId is provided (preferred), the question's data is retrieved and rendered.
   *
   * questionHTML and memoHTML:
   * If questionHTML and memoHTML is provided (not preferred), it's rendered immediately.
   */
  @Input() questionId: string;
  @Input() renderQuestion? = true;
  @Input() dev? = false;
  @Input() questionHTML?: string;
  @Input() memoHTML?: string;
  @Input() currentTopicNode?: any;
  @Input() questionIndex?: string;
  @Input() attempted? = false;
  @Input() topicSelectedQuestion? = false;
  @Input() assignmentIndicator? = false;
  @Input() tuitionStudent? = false;

  public Editor = ClassicEditor;
  public parts: any[];
  public questionData: any;
  private professionalBodyDescriptions = {
    [ProfessionalBodies.ASSA]: ProfessionalBodyDescriptions.ASSA,
    [ProfessionalBodies.IFOA]: ProfessionalBodyDescriptions.IFOA,
  };
  public questionFlag = false;
  public questionFlagText = "";
  constructor(
    public dataService: DataServiceService,
    private authService: AuthService,
    private adminService: AdminService,
    private notificationsService: NotificationsService,
    private sanitizer: DomSanitizer
  ) {}
  async render() {
    if (this.questionId) {
      this.loadQuestionParts(this.questionId, this.dev);
    }
  }

  ngOnInit() {
    this.render();
  }

  ngOnChanges() {
    this.render();
  }

  loadQuestionParts(
    questionId: string | undefined = undefined,
    parts: any = undefined
  ) {
    let collection = "questions";
    if (questionId) {
      this.dataService.afs
        .collection(collection)
        .doc(questionId)
        .snapshotChanges()
        .subscribe((data) => {
          const questionObject = data.payload.data() as QuestionData;
          this.questionData = questionObject;
          let parts = questionObject.parts;
          parts.forEach((part, index) => {
            // question
            parts[index]["question_raw"] = parts[index]["question_html"];
            // @ts-ignore
            parts[index]["question_html"] = this.dataService.formatQuestionHtml(
              part["question_html"],
              part["identifier"]
            );
            // hint
            if (parts[index]["hint"] != undefined) {
              parts[index]["hint_raw"] = parts[index]["hint"];
              parts[index]["hint_html_raw"] = parts[index]["hint_html"].replace(
                /<p>/g,
                '<p style="color:grey;"><i>'
              );
              parts[index]["hint_active"] = false;
              parts[index]["hint_html"] = this.dataService.formatQuestionHtml(
                part["hint_html_raw"],
                part["identifier"]
              );
            }
          });
          this.parts = parts;
        });
    } else {
      parts.forEach((part, index) => {
        // question
        parts[index]["question_raw"] = parts[index]["question_html"];
        parts[index]["question_html"] = this.dataService.formatQuestionHtml(
          part["question_html"],
          part["identifier"]
        );
        // hint
        if (parts[index]["hint"] != undefined) {
          parts[index]["hint_raw"] = parts[index]["hint"];
          parts[index]["hint_active"] = false;
          parts[index]["hint_html"] = this.dataService.formatQuestionHtml(
            part["hint_raw"],
            part["identifier"]
          );
        }
      });
      this.parts = parts;
    }
  }

  flagQuestion() {
    this.adminService.data.afs
      .collection("questions")
      .doc(this.questionId)
      .update({ flagged: true })
      .then((success) => {
        this.notificationsService.snack(
          "Thanks for letting us know. We will look into this, and may contact you to better understand the issue."
        );
        this.notificationsService.postToSlack(
          `Issue report on question ${this.questionId} - ${this.questionFlagText}`,
          SlackChannels.questionIssues
        );
        this.questionFlag = false;
        this.questionFlagText = "";
      });
  }

  editQuestion() {
    this.authService.router.navigate(["/admin/questions/" + this.questionId]);
  }
}
