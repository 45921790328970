import { Component, ViewChild } from "@angular/core";
import { AuthService } from "../../../services/auth/auth.service";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { CSVRecord } from "./CSVModel";
import * as ClassicEditor from "../../../ckeditor5";
import { NavigationEnd } from "@angular/router";
import { Review, ReviewType, SlackChannels, Tasks, ReviewGrade, ReviewGrades } from "../../../../../types/types";
import { MarkerService } from "../../../services/marker/marker.service";

@Component({
  selector: "app-admin",
  templateUrl: "./mark.component.html",
  styleUrls: ["./mark.component.scss"],
})
export class MarkComponent {
  public EditorAttempt = ClassicEditor;
  public EditorComments = ClassicEditor;
  public editor: any;
  key_ideas = 0;
  info = 0;
  public selfMarking = false;
  public attemptLoaded = false;
  private blured = false;
  private focused = false;
  public attemptData: Review = {
    subjectId: "",
    attemptText: "",
    email: "",
    email_completed: false,
    email_underway: false,
    feedback_quality: "0",
    mark: 0,
    partsToBeMarked: [],
    questionKey: "",
    question_identifier: "",
    reviewType: ReviewType.actuary,
    reviewed: false,
    reviewed_by: false,
    reviewer_comments_html: "",
    score: 0,
    score_mark: 0,
    grade: undefined,
    state: 0,
    source: "",
    structure_score: {
      key_ideas: 0,
      info_used: 0,
      concise: 0,
      idea_generated: 0,
    },
    subject: "",
    subjectName: "",
    time_submitted: 0,
    timestamp_submit: 0,
    user: "",
    creditsUsed: 0,
  };
  public sliders = [
    {
      name: "Key ideas identified",
      description:
        "Understood what was being asked and identified the key concepts clearly.",
      key: "key_ideas",
    },
    {
      name: "Use of information provided",
      description:
        "Effective use of the key pieces of information in the answer.",
      key: "info_used",
    },
    {
      name: "Conciseness",
      description:
        "Answer is clear and too the point, ensuring no time was wasted.",
      key: "concise",
    },
    {
      name: "Idea generation",
      description: "Provided a sufficient number if distinct ideas.",
      key: "idea_generated",
    },
  ];
  public key: string | undefined = "";
  public testEmails = [
    "mlsmith002@gmail.com",
    "gogroupinsure@gmail.com",
    "youltonk@gmail.com",
    "mineonly.mattie@gmail.com",
  ];
  public markerDataSubscribed = false;
  public firstLoad = true;
  public interval;
  public attemptTextReviewed = "";
  public reviewer_comments_html = "";
  private markerToggle = false;
  public reviewGrades = ReviewGrades;

  constructor(
    public authService: AuthService,
    private notificationsService: NotificationsService,
    public markerService: MarkerService
  ) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.key = this.getKeyFromUrl(this.authService.router.url);
        this.loadAttemptData();
      }
    });
  }

  /**
   * Allows an admin to click on "Question" to toggle between the marker and self-assessment views for testing purposes.
   */
  toggle() {
    if (this.authService.admin) {
      this.markerToggle = !this.markerToggle;
      this.selfMarking = !this.selfMarking;
    }
  }

  getTimeLeft() {
    if (this.attemptData.state_timestamp) {
      const hoursUntilUnlock = 6;
      const unlocksAt =
        this.attemptData.state_timestamp + hoursUntilUnlock * 60 * 60 * 1000;
      const minsLeft = (unlocksAt - new Date().getTime()) / (1000 * 60);
      let timeLeftMessage = `${Math.floor(
        minsLeft
      )} minutes remaining until the attempt will be opened up to
          other markers.`;
      if (Math.floor(minsLeft) <= 1) {
        timeLeftMessage = `The attempt will be opened up to
            other markers within the next 15 minutes.`;
      }
      this.attemptData.timeLeft = timeLeftMessage;
    }
  }

  startTimer() {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.getTimeLeft();
    }, 30 * 1000);
  }

  getKeyFromUrl(url: string) {
    return url.replace("/mark/", "");
  }

  resetAttemptTextReviewed() {
    this.attemptTextReviewed = this.cleanMathMl(
      this.attemptData["attemptText"]
    );
    this.reviewer_comments_html = "";
  }

  cleanMathMl(input) {
    /** Replaces the mspace linebreak with a new equation since it does not render properly on reload  */
    const find = '<mspace linebreak="newline">&nbsp;</mspace>';
    const replace =
      "</math><br/><math xmlns=“http://www.w3.org/1998/Math/MathML”>";
    return input.split(find).join(replace);
  }

  setAttemptTextReviewed() {
    if (this.attemptData["attemptTextReviewed"]) {
      this.attemptTextReviewed = this.cleanMathMl(
        this.attemptData["attemptTextReviewed"]
      );
    } else {
      this.attemptTextReviewed = this.cleanMathMl(
        this.attemptData["attemptText"]
      );
    }
  }

  addDataToAttemptObject() {
    this.attemptData.attemptTextReviewed = this.cleanMathMl(
      this.attemptTextReviewed
    );
    this.attemptData.reviewer_comments_html = this.reviewer_comments_html;
  }

  saveData() {
    if (this.key) {
      this.addDataToAttemptObject();
      if (!this.attemptData["log"]) {
        this.attemptData["log"] = [];
      }
      this.attemptData["log"].push({
        description: "Marker saved review",
        marker: this.authService.userInfo["email"],
        timestamp: new Date().getTime(),
      });
      this.authService.fs
        .collection("reviews")
        .doc(this.key)
        .update(this.attemptData)
        .then((d) => {
          this.notificationsService.snack("Review saved");
          this.notificationsService.postToSlack(
            `Marker saved review ${this.key}`,
            SlackChannels.reviewManagement
          );
        });
    }
  }

  loadAttemptData() {
    this.authService.fs
      .collection("reviews")
      .doc(this.key)
      .snapshotChanges()
      .subscribe((data) => {
        if (data.payload.data() && data.payload.id === this.key) {
          this.attemptData = data.payload.data() as Review;
          this.getTimeLeft();
          this.setAttemptTextReviewed();
          this.reviewer_comments_html = this.attemptData.reviewer_comments_html;
          this.selfMarking =
            this.authService.userDetails.uid === this.attemptData["user"];
          if (!this.attemptData["log"]) {
            this.attemptData["log"] = [];
          }
          if (this.markerToggle) {
            this.selfMarking = !this.selfMarking;
          }
          if (this.attemptData["state"] === 2 && !this.authService.admin) {
            this.closePage();
          } else if (
            this.attemptData["state"] === 2 &&
            this.authService.admin
          ) {
            console.log("Admin query result");
          } else if (this.attemptData["state"] === 1) {
            if (
              this.attemptData["reviewed_by"] !==
              this.authService.userInfo["uid"]
            ) {
              this.closePage();
            } else {
              this.firstLoad = false;
            }
          } else if (this.attemptData["state"] === 0) {
            if (!this.firstLoad) {
              this.closePage();
            } else {
              this.setReviewStatus(1);
              this.firstLoad = false;
            }
          }
        }
      });
  }

  closePage() {
    if (this.selfMarking) {
      this.key = undefined;
      this.authService.goToDesktop(this.attemptData["subject"]);
    } else {
      this.goToSubjects();
    }
  }

  cancelReview() {
    this.notificationsService
      .snackConfirm(`Are you sure you want to cancel this review?`, 10000)
      .onAction()
      .subscribe(() => {
        this.resetAttemptTextReviewed();
        this.resetAttemptScores();
        this.setReviewStatus(0);
      });
  }

  resetAttemptScores() {
    this.attemptData.score_mark = 0;
    this.attemptData.structure_score["concise"] = 0;
    this.attemptData.structure_score["idea_generated"] = 0;
    this.attemptData.structure_score["info_used"] = 0;
    this.attemptData.structure_score["key_ideas"] = 0;
  }

  goToSubjects() {
    this.authService.router.navigate(["/subjects"]);
    this.key = undefined;
  }

  goToMarker() {
    this.authService.router.navigate(["/marker"]);
    this.key = undefined;
  }

  goToDesktop() {
    this.authService.goToDesktop(this.attemptData["subject"]);
    this.key = undefined;
  }

  setReviewStatus(state) {
    this.addDataToAttemptObject();
    // Cancel review
    if (state === 0 && this.attemptData["state"] === 1) {
      this.attemptData["state"] = state;
      this.attemptData["state_timestamp"] = new Date().valueOf();
      this.attemptData["reviewed_by"] = false;
      this.attemptData["reviewed_by_email"] = "";
      if (!this.attemptData["log"]) {
        this.attemptData["log"] = [];
      }
      this.attemptData["log"].push({
        description: "Marker cancelled review",
        marker: this.authService.userInfo["email"],
        timestamp: new Date().getTime(),
      });
      if (!this.selfMarking) {
        this.notificationsService.postToSlack(
          `Marker cancelled review ${this.key}`,
          SlackChannels.reviewManagement
        );
      }
      this.authService.fs
        .collection("reviews")
        .doc(this.key)
        .update(this.attemptData)
        .then((d) => {
          console.log("Review cancelled");
        })
        .catch((e) => {
          this.notificationsService.postToSlack(
            `Error cancelling review ${this.key}`,
            SlackChannels.reviewManagement
          );
        });
    } else if (state === 1 && this.attemptData["state"] === 0) {
      this.attemptData["state"] = 1;
      this.attemptData["state_timestamp"] = new Date().valueOf();
      this.attemptData["reviewed_by"] = this.authService.userInfo["uid"];
      this.attemptData["reviewed_by_email"] =
        this.authService.userInfo["email"];
      if (!this.attemptData["log"]) {
        this.attemptData["log"] = [];
      }
      this.attemptData["log"].push({
        description: "Marker took ownership of review",
        marker: this.authService.userInfo["email"],
        timestamp: new Date().getTime(),
      });
      if (!this.attemptData["costPerCredit"]) {
        this.attemptData["costPerCredit"] = this.markerService.costPerCredit;
      }
      this.authService.fs
        .collection("reviews")
        .doc(this.key)
        .update(this.attemptData)
        .then((d) => {
          if (!this.selfMarking) {
            this.notificationsService.postToSlack(
              `Marker took ownership of review ${this.key} at R${this.attemptData["costPerCredit"]} per credit`,
              SlackChannels.reviewManagement
            );
          }
        });
    } else if (state === 2 && this.attemptData["state"] === 1) {
      this.attemptData["reviewed"] = true;
      this.attemptData["state"] = 2;
      this.attemptData["timestamp"] = Date.now();
      this.attemptData["reviewed_by"] = this.authService.userInfo["uid"];
      this.notificationsService.createTask(Tasks.reviewComplete, {
        review: this.attemptData,
      });
      if (!this.selfMarking) {
        this.notificationsService.postToSlack(
          `Marker completed review ${this.key}`,
          SlackChannels.reviewManagement
        );
        if (!this.attemptData["log"]) {
          this.attemptData["log"] = [];
        }
        this.attemptData["log"].push({
          description: "Marker completed review",
          marker: this.authService.userInfo["email"],
          timestamp: new Date().getTime(),
        });
      }
      this.authService.fs
        .collection("reviews")
        .doc(this.key)
        .update(this.attemptData)
        .then((d) => {
          if (this.selfMarking) {
            this.notificationsService.snack(
              "Nice! Your attempt and notes have been saved in 'Your Progress'"
            );
            this.goToDesktop();
          } else {
            this.notificationsService.snack(
              "Review completed & candidate notified. Thanks for your time!"
            );
            this.goToMarker();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.notificationsService.snack("Access not authorized");
      return this.goToSubjects();
    }
  }

  saveReview() {
    this.saveData();
  }

  finishReview() {
    if (!this.authService.internetConnected) {
      return this.notificationsService.snack(
        "Internet connection error. Save your work and refresh the page."
      );
    } 
    if(this.attemptData["grade"] === undefined) {
      this.notificationsService.snack("Please select a grade for the review");
      return;
    }
    if(this.attemptData["score_mark"] === undefined) {
      this.notificationsService.snack("Please enter a score for the review");
      return;
    }
    this.attemptData["score"] = Math.round(
      (this.attemptData["score_mark"] / this.attemptData["mark"]) * 100
    );
    this.notificationsService
      .snackConfirm(`Confirm score of ${this.attemptData["score"]}% and grade of ${this.attemptData["grade"]}?`, 10000)
      .onAction()
      .subscribe(() => {
        this.setReviewStatus(2);
      });
  }

  focus($event) {
    this.focused = true;
    this.blured = false;
  }

  blur($event) {
    this.focused = false;
    this.blured = true;
  }

  openSheet(sheetLink: string) {
    window.open(sheetLink, "_blank");
  }

  onReady(editor) {
    if (editor) {
      this.editor = editor;
    }
  }
}
